import React from "react";
import { Link } from "react-router-dom";
import ConsultTab from "../Sub-Services/ConsultTabs";

function Consultation() {
  return (
    <div>
      <div className="relative overflow-hidden">
        {/* Gradients */}
        <div
          aria-hidden="true"
          className="flex absolute -top-96 start-1/2 transform -translate-x-1/2"
        >
          <div className="bg-gradient-to-r from-violet-300/50 to-purple-100 blur-3xl w-[25rem] h-[44rem] rotate-[-60deg] transform -translate-x-[10rem]" />
          <div className="bg-gradient-to-tl from-blue-50 via-blue-100 to-blue-50 blur-3xl w-[90rem] h-[50rem] rounded-full origin-top-left -rotate-12 -translate-x-[15rem]" />
        </div>
        {/* End Gradients */}
        <div className="relative z-10">
          <div className="max-w-[80rem] mx-auto mt-32 px-4 sm:px-6 lg:px-8 py-10 lg:py-16">
            <div className="max-w-[80rem] text-start mx-auto">
              <div className="flex items-center flex-wrap gap-4">
                <Link
                  className="inline-block text-sm font-medium tracking-tight bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent"
                  to="/"
                >
                  Home
                </Link>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="4"
                  height="8"
                  viewBox="0 0 4 8"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.50225 0.5L0 0.98755L2.95244 4L0.0073503 7.0051L0.50225 7.5L4.00225 4L0.50225 0.5Z"
                    fill="#19191B"
                  ></path>
                </svg>
                <Link
                  className="inline-block text-sm font-medium tracking-tight bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent"
                  to="/services"
                >
                  Services
                </Link>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="4"
                  height="8"
                  viewBox="0 0 4 8"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.50225 0.5L0 0.98755L2.95244 4L0.0073503 7.0051L0.50225 7.5L4.00225 4L0.50225 0.5Z"
                    fill="#19191B"
                  ></path>
                </svg>
                <Link
                  className="inline-block text-sm font-medium tracking-tight bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent"
                  to="/services/migration"
                >
                  Migration
                </Link>
              </div>
              {/* Title */}
              <div className="mt-5 max-w-2xl">
                <h1 className="block font-light logo text-gray-800 text-3xl sm:text-4xl md:text-5xl lg:text-6xl">
                  Consultation
                </h1>
              </div>
              {/* End Title */}
              <div className="mt-5 max-w-3xl">
                <p className="text-base sm:text-lg text-gray-600">
                  At BZ Softech, we’re at the forefront of technology
                  innovation, offering a range of services designed to enhance
                  connectivity, streamline operations, and drive business
                  success. Our expert team delivers tailored solutions that
                  address the specific needs of your organization, ensuring
                  efficiency and growth in a rapidly evolving digital landscape.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative max-w-[80rem] mx-auto px-4 md:px-8 pb-24">
        <div className="absolute inset-x-0 hidden xl:px-44 top-2 md:block md:px-20 lg:px-28">
          <img
            className="w-full"
            src="https://cdn.rareblocks.xyz/collection/celebration/images/steps/2/curved-dotted-line.svg"
            alt=""
          />
        </div>

        <div className="relative grid grid-cols-1 text-center gap-y-12 md:grid-cols-3 gap-x-12">
          <div>
            <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
              <span className="text-xl font-semibold text-gray-700">1</span>
            </div>
            <h3 className="mt-6 text-xl sm:text-2xl font-semibold leading-tight text-black md:mt-10">
              Choose Consultation
            </h3>
            <p className="mt-4 text-base sm:text-lg text-gray-600">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officia consequat duis enim velit mollit.
            </p>
          </div>

          <div>
            <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
              <span className="text-xl font-semibold text-gray-700">2</span>
            </div>
            <h3 className="mt-6 text-xl sm:text-2xl font-semibold leading-tight text-black md:mt-10">
              Scheduling
            </h3>
            <p className="mt-4 text-base sm:text-lg text-gray-600">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officia consequat duis enim velit mollit.
            </p>
          </div>

          <div>
            <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
              <span className="text-xl font-semibold text-gray-700">3</span>
            </div>
            <h3 className="mt-6 text-xl sm:text-2xl font-semibold leading-tight text-black md:mt-10">
              Payment & Consult
            </h3>
            <p className="mt-4 text-base sm:text-lg text-gray-600">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officia consequat duis enim velit mollit.
            </p>
          </div>
        </div>
      </div>

      <ConsultTab />
    </div>
  );
}

export default Consultation;
