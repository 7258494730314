import React from "react";
import { Link } from "react-router-dom";

function Integration() {
  return (
    <div>
      <div>
        <div className="relative overflow-hidden">
          {/* Gradients */}
          <div
            aria-hidden="true"
            className="flex absolute -top-96 start-1/2 transform -translate-x-1/2"
          >
            <div className="bg-gradient-to-r from-violet-300/50 to-purple-100 blur-3xl w-[25rem] h-[44rem] rotate-[-60deg] transform -translate-x-[10rem]" />
            <div className="bg-gradient-to-tl from-blue-50 via-blue-100 to-blue-50 blur-3xl w-[90rem] h-[50rem] rounded-fulls origin-top-left -rotate-12 -translate-x-[15rem]" />
          </div>
          {/* End Gradients */}
          <div className="relative z-10">
            <div className="max-w-[80rem] mx-auto mt-32 px-4 sm:px-6 lg:px-8 py-10 lg:py-16">
              <div className="max-w-[80rem] text-start mx-auto">
                <div class="flex items-center flex-wrap gap-4">
                  <Link
                    class="inline-block text-sm font-medium tracking-tight bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent"
                    to="/"
                  >
                    Home
                  </Link>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="4"
                    height="8"
                    viewbox="0 0 4 8"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.50225 0.5L0 0.98755L2.95244 4L0.0073503 7.0051L0.50225 7.5L4.00225 4L0.50225 0.5Z"
                      fill="#19191B"
                    ></path>
                  </svg>
                  <Link
                    class="inline-block text-sm font-medium tracking-tight bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent"
                    to="/services"
                  >
                    Services
                  </Link>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="4"
                    height="8"
                    viewbox="0 0 4 8"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.50225 0.5L0 0.98755L2.95244 4L0.0073503 7.0051L0.50225 7.5L4.00225 4L0.50225 0.5Z"
                      fill="#19191B"
                    ></path>
                  </svg>
                  <Link
                    class="inline-block text-sm font-medium tracking-tight bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent"
                    href="/services/integration"
                  >
                    Integration
                  </Link>
                </div>
                {/* Title */}
                <div className="mt-5 max-w-2xl">
                  <h1 className="block font-light logo text-gray-800 text-4xl md:text-5xl lg:text-6xl">
                    Integration
                  </h1>
                </div>
                {/* End Title */}
                <div className="mt-5 max-w-3xl">
                  <p className="text-lg text-justify text-gray-600">
                    At BZ Softech, we specialize in providing cutting-edge
                    integration services that help businesses streamline their
                    processes and improve operational efficiency. We offer a
                    range of solutions tailored to your specific needs,
                    including:
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-3 md:px-8 my-8">
          <p className="text-gray-700 text-justify">
            We offer comprehensive Oracle APEX services, enabling seamless
            integration with various hardware components and third-party APIs.
            Whether you're looking to connect your systems to external hardware
            such as biometric scanners, IoT devices, or other peripherals, or
            require advanced API integrations with platforms like .NET, Python,
            and JavaScript, we have you covered. Our team excels in both backend
            and frontend integrations, allowing for smooth, responsive user
            experiences.
          </p>
        </div>

        {/* Image placeholder for integration */}
        <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-48">
          <img
            src="https://a.storyblok.com/f/140059/x/ad13942791/integrations.svg"
            alt="Integration Services"
            className="w-full max-w-full h-84"
          />
        </div>

        <div className=" max-w-7xl mx-auto px-4 md:px-32 my-24 space-y-10">
          <h1 className="font-bold logo text-gray-800 md:text-4xl text-2xl">
            Integration Solutions We Offer
          </h1>

          <div className="space-y-4">
            <h1 className="font-light logo text-gray-800 text-4xl">
              1. Oracle APEX Integration
            </h1>
            <p className="text-gray-600 text-justify">
              We provide Oracle APEX integration services that allow you to
              connect your legacy systems and databases with modern platforms
              seamlessly. Whether it's integrating your Oracle database with
              external systems, building web APIs for enhanced functionality, or
              creating user-friendly APEX apps, we ensure efficient and scalable
              solutions.
            </p>
          </div>

          <div className="space-y-4">
            <h1 className="font-light logo text-gray-800 text-4xl">
              2. Hardware Integration
            </h1>
            <p className="text-gray-600 text-justify">
              Our hardware integration services include connecting Oracle APEX
              applications to various external hardware components such as
              biometric devices, sensors, RFID scanners, and other peripherals
              to create more interactive and data-rich environments.
            </p>
          </div>

          <div className="space-y-4">
            <h1 className="font-light logo text-gray-800 text-4xl">
              3. API Integration
            </h1>
            <p className="text-gray-600 text-justify">
              We excel in integrating with third-party APIs, allowing your
              Oracle APEX applications to interact with platforms such as
              payment gateways, CRM systems, and cloud-based services. Whether
              it's REST, SOAP, or language-specific APIs like .NET, Python, and
              JavaScript, we ensure smooth data exchange and functionality.
            </p>
          </div>

          <div className="space-y-4">
            <h1 className="font-light logo text-gray-800 text-4xl">
              4. Language-Specific API Integration
            </h1>
            <p className="text-gray-600 text-justify">
              Our team is proficient in integrating Oracle APEX with APIs in
              various programming languages like .NET, Python, JavaScript, and
              more. This allows for dynamic, real-time interactions with other
              applications, enhancing the functionality and performance of your
              systems.
            </p>
          </div>

          <div className="space-y-4">
            <h1 className="font-light logo text-gray-800 text-4xl">
              5. Custom Oracle APEX Solutions
            </h1>
            <p className="text-gray-600 text-justify">
              We create custom Oracle APEX solutions tailored to your business
              needs. From integrating complex data workflows to optimizing user
              experiences, we help businesses leverage Oracle APEX to its full
              potential.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Integration;
