import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { Link } from "react-router-dom";
import infrastructure from "../../assets/infrastructure_support.png";
import development from "../../assets/custom_development.png";
import cloud from "../../assets/cloud_services.png";
import sqa from "../../assets/quality_assurance_services.png";
import figma from "../../assets/figma.svg";
import reactLogo from "../../assets/react.svg"; // Renamed to avoid conflict
import mongo from "../../assets/mongo.svg";
import oracle from "../../assets/oracle.svg";
import firebase from "../../assets/firebase.svg";
import vercel from "../../assets/next.svg";
import node from "../../assets/node.svg";

function HeroSection() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const slideRef = useRef(null);
  const logosRef = useRef([]);

  const slides = [
    {
      slogan: "Your IT Outsourcing Ally",
      title: "Fostering Collaborative Connectivity for Excellence",
      logos: [
        { src: infrastructure, width: "180px", height: "160px" },
        { src: development, width: "170px", height: "150px" },
        { src: cloud, width: "145px", height: "120px" },
        { src: sqa, width: "190px", height: "170px" },
      ],
      buttons: [{ text: "See our Services", link: "/services", primary: true }],
    },
    {
      slogan: "A Gateway to Cutting-Edge Technologies",
      title: "Delivering Tailored IT Solutions to Transform Businesses",
      logos: [
        { src: oracle, width: "120px", height: "65px" },
        { src: vercel, width: "55px", height: "45px" },
        { src: figma, width: "30px", height: "40px" },
        { src: reactLogo, width: "50px", height: "55px" }, // Updated reference
        { src: mongo, width: "70px", height: "50px" },
        { src: firebase, width: "45px", height: "60px" },
      ],
      buttons: [{ text: "See our Products", link: "/products", primary: true }],
    },
  ];

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  useEffect(() => {
    if (slideRef.current) {
      gsap.fromTo(
        slideRef.current,
        { opacity: 0, y: 50 },
        { opacity: 1, y: 0, duration: 1.5, ease: "power2.out" }
      );
    }

    const interval = setInterval(() => {
      handleNext();
    }, 4000);

    return () => clearInterval(interval);
  }, [currentSlide]);

  useEffect(() => {
    logosRef.current.forEach((logo, index) => {
      if (logo) {
        // Ensure logo exists
        gsap.fromTo(
          logo,
          { x: 100, opacity: 0 },
          {
            x: 0,
            opacity: 1,
            duration: 1,
            ease: "power2.out",
            delay: index * 0.2,
          }
        );
      }
    });
  }, [currentSlide]);

  const handlePrevious = () => {
    const prevSlide = (currentSlide - 1 + slides.length) % slides.length;
    animateSlideChange(prevSlide);
  };

  const handleNext = () => {
    const nextSlide = (currentSlide + 1) % slides.length;
    animateSlideChange(nextSlide);
  };

  const animateSlideChange = (newSlide) => {
    if (slideRef.current) {
      gsap.to(slideRef.current, {
        opacity: 0,
        y: -50,
        duration: 1,
        ease: "power2.in",
        onComplete: () => {
          setCurrentSlide(newSlide);
          gsap.fromTo(
            slideRef.current,
            { opacity: 0, y: 50 },
            { opacity: 1, y: 0, duration: 1.5, ease: "power2.out" }
          );
        },
      });
    }
  };

  const DesktopHero = () => (
    <div className="relative overflow-hidden min-h-screen mt-0  bg">
      <div className="z-10 flex flex-col items-center justify-center h-screen my-auto mx-auto relative">
        {/* Previous Button */}
        <div className="absolute left-12 top-1/2 transform -translate-y-1/2">
          <button
            aria-label="Previous Slide"
            className="w-12 h-12 p-2 bg-blue-300 text-white rounded-full focus:outline-none"
            onClick={handlePrevious}
          >
            <i className="fa-light fa-arrow-left text-blue-800"></i>
          </button>
        </div>

        {/* Next Button */}
        <div className="absolute right-12 top-1/2 transform -translate-y-1/2">
          <button
            aria-label="Next Slide"
            className="w-12 h-12 p-2 bg-blue-300 text-white rounded-full focus:outline-none"
            onClick={handleNext}
          >
            <i className="fa-light fa-arrow-right text-blue-800"></i>
          </button>
        </div>

        {/* Slide Content */}
        <div ref={slideRef} className="relative text-center md:mt-16">
          <div className="max-w-6xl mx-auto">
            <p className="inline-block logo text-xl font-medium text-gray-300">
              {slides[currentSlide].slogan}
            </p>
            <h1 className="block logo font-bold text-6xl text-gray-100 mt-5 max-w-4xl mx-auto">
              {slides[currentSlide].title}
            </h1>
            <div className="flex space-x-8 flex-wrap justify-around items-center mb-16 mt-12">
              {slides[currentSlide].logos.map((logo, index) => (
                <img
                  key={index}
                  src={logo.src}
                  alt={`Logo ${index}`}
                  style={{
                    width: logo.width,
                    height: logo.height,
                    maxWidth: "100%",
                    height: "auto",
                  }}
                  ref={(el) => (logosRef.current[index] = el)}
                />
              ))}
            </div>
            <div className="gap-3 flex flex-row justify-center">
              {slides[currentSlide].buttons.map((button, index) => (
                <Link
                  to={button.link}
                  className={`relative group inline-block py-4 px-6 text-blue-900 hover:text-white font-semibold rounded-md overflow-hidden transition duration-500 ${
                    button.primary ? "bg-white" : "bg-gray-500"
                  }`}
                  key={index}
                >
                  <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-105 transition duration-500"></div>
                  <div className="relative flex items-center justify-center">
                    <span className="mr-4">{button.text}</span>
                    <span>
                      <svg
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.92 0.62C12.8185 0.375651 12.6243 0.181475 12.38 0.0799999C12.2598 0.028759 12.1307 0.00157999 12 0H2C1.73478 0 1.48043 0.105357 1.29289 0.292893C1.10536 0.48043 1 0.734784 1 1C1 1.26522 1.10536 1.51957 1.29289 1.70711C1.48043 1.89464 1.73478 2 2 2H9.59L1.29 10.29C1.19627 10.383 1.12188 10.4936 1.07111 10.6154C1.02034 10.7373 0.994202 10.868 0.994202 11C0.994202 11.132 1.02034 11.2627 1.07111 11.3846C1.12188 11.5064 1.19627 11.617 1.29 11.71C1.38296 11.8037 1.49356 11.8781 1.61542 11.9289C1.73728 11.9797 1.86799 12.0058 2 12.0058C2.13201 12.0058 2.26272 11.9797 2.38458 11.9289C2.50644 11.8781 2.61704 11.8037 2.71 11.71L11 3.41V11C11 11.2652 11.1054 11.5196 11.2929 11.7071C11.4804 11.8946 11.7348 12 12 12C12.2652 12 12.5196 11.8946 12.7071 11.7071C12.8946 11.5196 13 11.2652 13 11V1C12.9984 0.869323 12.9712 0.740222 12.92 0.62Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const MobileHero = () => (
    <div className="relative overflow-hidden min-h-screen  bg px-4">
      <div className="z-10 flex flex-col items-center justify-center h-screen my-auto mx-auto relative">
        {/* Slide Content */}
        <div ref={slideRef} className="relative text-center">
          <div className="mx-auto">
            <p className="inline-block logo text-sm font-medium text-gray-300">
              {slides[currentSlide].slogan}
            </p>
            <h1 className="block logo font-bold text-2xl md:text-3xl text-gray-100 mt-3 mx-auto">
              {slides[currentSlide].title}
            </h1>
            <div className="grid grid-cols-2 gap-4 justify-items-center items-center my-8">
              {slides[currentSlide].logos.map((logo, index) => (
                <img
                  key={index}
                  src={logo.src}
                  alt={`Logo ${index}`}
                  style={{ width: "auto", height: "auto", maxHeight: "60px" }} // Custom height for mobile
                  ref={(el) => (logosRef.current[index] = el)}
                />
              ))}
            </div>
            <div className="flex flex-col gap-3  ">
              {slides[currentSlide].buttons.map((button, index) => (
                <Link
                  to={button.link}
                  className={`relative border  border-gray-200 group py-3 px-4 text-blue-900 z-10 hover:text-white font-semibold rounded-md overflow-hidden transition duration-500 ${
                    button.primary ? "bg-white" : "bg-gray-500"
                  }`}
                  key={index}
                >
                  <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-105 transition duration-500"></div>
                  <div className="relative flex items-center justify-center">
                    <span className="mr-2">{button.text}</span>
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.92 0.62C12.8185 0.375651 12.6243 0.181475 12.38 0.0799999C12.2598 0.028759 12.1307 0.00157999 12 0H2C1.73478 0 1.48043 0.105357 1.29289 0.292893C1.10536 0.48043 1 0.734784 1 1C1 1.26522 1.10536 1.51957 1.29289 1.70711C1.48043 1.89464 1.73478 2 2 2H9.59L1.29 10.29C1.19627 10.383 1.12188 10.4936 1.07111 10.6154C1.02034 10.7373 0.994202 10.868 0.994202 11C0.994202 11.132 1.02034 11.2627 1.07111 11.3846C1.12188 11.5064 1.19627 11.617 1.29 11.71C1.38296 11.8037 1.49356 11.8781 1.61542 11.9289C1.73728 11.9797 1.86799 12.0058 2 12.0058C2.13201 12.0058 2.26272 11.9797 2.38458 11.9289C2.50644 11.8781 2.61704 11.8037 2.71 11.71L11 3.41V11C11 11.2652 11.1054 11.5196 11.2929 11.7071C11.4804 11.8946 11.7348 12 12 12C12.2652 12 12.5196 11.8946 12.7071 11.7071C12.8946 11.5196 13 11.2652 13 11V1C12.9984 0.869323 12.9712 0.740222 12.92 0.62Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // Corrected return statement using JSX syntax
  return isMobile ? <MobileHero /> : <DesktopHero />;
}

export default HeroSection;
