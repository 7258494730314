import React from 'react'

function VisMis() {
    return (
        <>
            <div className="max-w-[80rem] mx-auto mt-24">
                <div className="flex flex-wrap -mx-4">
                    <div className="w-full lg:w-1/2 px-4 mb-12 lg:mb-0 flex items-center justify-center">
                        <div className="max-w-lg text-center lg:text-left">
                            <p className="text-3xl font-bold text-gray-800 mb-6 font-serif italic">Mission</p>
                            <p className="text-lg text-gray-700 mb-6 text-justify">We are passionately committed to pioneering technological automation that not only streamlines connectivity but also enhances collaborative efforts across diverse platforms. By individuals to navigate and thrive in an increasingly complex digital ecosystem. Our focus is on driving operational efficiency, fostering innovation, and creating opportunities for growth in a rapidly evolving technological landscape.</p>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 px-4 flex items-center justify-center">
                        <div className="max-w-lg text-center lg:text-left">
                            <p className="text-3xl font-bold text-gray-800 mb-6 font-serif italic">Vision</p>
                            <p className="text-lg text-gray-700 mb-6 text-justify">We envision establishing ourselves as a trusted global partner, dedicated to unlocking opportunities for 10,000 forward-thinking individuals to shape the future of software excellence by 2030. Through our commitment to innovation and collaboration, we aim to foster a dynamic environment where visionary minds can drive groundbreaking advancements and set new standards in the industry.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VisMis
