import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Mail,
  Search,
  Trash2,
  Reply,
  Star,
  User,
  Menu,
} from "lucide-react";

function Mailbox() {
  const [emails, setEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [checkedEmails, setCheckedEmails] = useState({});

  useEffect(() => {
    fetchEmails();
  }, []);

  const fetchEmails = async () => {
    try {
      const response = await axios.get("https://bzs-server.vercel.app/mail/getmail");
      const transformedData = response.data.map(email => ({
        _id: email._id,
        from: `${email.firstName} ${email.lastName} <${email.eMail}>`,
        subject: email.subject || "No subject",
        body: email.message,
        date: email.date || new Date().toISOString(),
        isStarred: false
      }));
      setEmails(transformedData);
    } catch (error) {
      console.error("Error fetching emails:", error);
    }
  };

  const handleDelete = async () => {
    const idsToDelete = Object.keys(checkedEmails).filter(id => checkedEmails[id]);
    
    for (const id of idsToDelete) {
      try {
        await axios.delete(`https://bzs-server.vercel.app/mail/deleteMail`, { params: { id } });
      } catch (error) {
        console.error(`Error deleting email with id ${id}:`, error);
      }
    }

    setCheckedEmails({});
    fetchEmails();

    if (selectedEmail && idsToDelete.includes(selectedEmail._id)) {
      setSelectedEmail(null);
    }
  };

  const handleEmailClick = (email) => setSelectedEmail(email);

  const toggleCheckEmail = (emailId, e) => {
    e.stopPropagation();
    setCheckedEmails(prev => ({ ...prev, [emailId]: !prev[emailId] }));
  };

  const toggleStar = (email) => {
    const updatedEmails = emails.map(e =>
      e._id === email._id ? { ...e, isStarred: !e.isStarred } : e
    );
    setEmails(updatedEmails);
  };

  const handleCheckAll = () => {
    const allChecked = emails.every(email => checkedEmails[email._id]);
    const newCheckedEmails = {};
    emails.forEach(email => {
      newCheckedEmails[email._id] = !allChecked;
    });
    setCheckedEmails(newCheckedEmails);
  };

  const handleReply = (email) => {
    const mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${email.from}`;
    window.open(mailtoLink, "_blank");
  };

  const filteredEmails = emails.filter(email =>
    email.from.toLowerCase().includes(searchTerm.toLowerCase()) ||
    email.subject.toLowerCase().includes(searchTerm.toLowerCase()) ||
    email.body.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="flex h-screen bg-gray-50">
      <div className="flex-1 flex flex-col">
        <header className="bg-white shadow-sm p-4 flex justify-between items-center">
          <div className="flex items-center">
            <Menu className="w-5 h-5 text-gray-400 mr-4" />
            <h1 className="text-xl font-semibold text-gray-800">Inbox</h1>
          </div>
          <div className="relative">
            <input
              type="text"
              placeholder="Search emails..."
              className="pl-10 pr-4 py-2 border border-gray-200 rounded-full w-64 focus:outline-none focus:ring-2 focus:ring-blue-300 text-sm"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search className="w-4 h-4 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
          </div>
        </header>

        <div className="flex-1 flex overflow-hidden">
          <div className="w-1/3 border-r border-gray-200 overflow-y-auto bg-white">
            <div className="sticky top-0 bg-white border-b border-gray-200 px-4 py-2 flex justify-between items-center">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-blue-500 rounded border-gray-300"
                  checked={emails.length > 0 && emails.every((email) => checkedEmails[email._id])}
                  onChange={handleCheckAll}
                />
                <span className="ml-2 text-sm text-gray-600">Select All</span>
              </label>
              <button
                className="text-gray-400 hover:text-red-500 transition duration-150 ease-in-out"
                onClick={handleDelete}
                disabled={Object.values(checkedEmails).every((v) => !v)}
              >
                <Trash2 className="w-4 h-4" />
              </button>
            </div>
            {emails.length === 0 ? (
              <div className="p-4 text-center text-gray-500 text-sm">No emails to display</div>
            ) : (
              filteredEmails.map((email) => (
                <div
                  key={email._id}
                  className={`p-3 border-b border-gray-100 cursor-pointer hover:bg-gray-50 transition duration-150 ease-in-out ${
                    selectedEmail === email ? "bg-blue-50" : ""
                  }`}
                  onClick={() => handleEmailClick(email)}
                >
                  <div className="flex items-center mb-1">
                    <input
                      type="checkbox"
                      className="form-checkbox h-4 w-4 text-blue-500 rounded border-gray-300 mr-3"
                      checked={checkedEmails[email._id] || false}
                      onChange={(e) => toggleCheckEmail(email._id, e)}
                    />
                    <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center mr-3">
                      <User className="w-4 h-4 text-gray-500" />
                    </div>
                    <div className="flex-grow">
                      <h2 className="font-semibold text-gray-800 text-sm truncate">{email.from}</h2>
                      <p className="text-xs text-gray-600 truncate">{email.subject}</p>
                    </div>
                    <Star
                      className={`w-4 h-4 ${email.isStarred ? "text-yellow-400" : "text-gray-300"} cursor-pointer`}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleStar(email);
                      }}
                    />
                  </div>
                  <p className="text-xs text-gray-400 ml-14">
                    {new Date(email.date).toLocaleString([], { dateStyle: "short", timeStyle: "short" })}
                  </p>
                </div>
              ))
            )}
          </div>

          {selectedEmail ? (
            <div className="w-2/3 overflow-y-auto bg-white p-6">
              <div className="mb-6">
                <h2 className="text-xl font-semibold text-gray-800 mb-2">{selectedEmail.subject}</h2>
                <div className="flex items-center justify-between text-sm text-gray-500 mb-4">
                  <div className="flex items-center">
                    <div className="w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center mr-3">
                      <User className="w-5 h-5 text-gray-500" />
                    </div>
                    <div>
                      <p className="font-medium">{selectedEmail.from}</p>
                      <p className="text-xs">To: me</p>
                    </div>
                  </div>
                  <p className="text-xs">{new Date(selectedEmail.date).toLocaleString([], { dateStyle: "long", timeStyle: "short" })}</p>
                </div>
                <div className="flex space-x-2">
                  <button
                    className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-full text-sm flex items-center transition duration-150 ease-in-out"
                    onClick={() => handleReply(selectedEmail)}
                  >
                    <Reply className="w-4 h-4 mr-2" /> Reply
                  </button>
                  <button
                    className="bg-gray-100 hover:bg-gray-200 text-gray-700 px-4 py-2 rounded-full text-sm flex items-center transition duration-150 ease-in-out"
                    onClick={() => toggleStar(selectedEmail)}
                  >
                    <Star className={`w-4 h-4 mr-2 ${selectedEmail.isStarred ? "text-yellow-400" : ""}`} /> 
                    {selectedEmail.isStarred ? "Unstar" : "Star"}
                  </button>
                </div>
              </div>
              <div className="border-t border-gray-100 pt-6">
                <p className="text-gray-800 text-sm whitespace-pre-wrap">{selectedEmail.body}</p>
              </div>
            </div>
          ) : (
            <div className="w-2/3 flex items-center justify-center text-gray-400 bg-gray-50">
              <Mail className="w-16 h-16 mb-4" />
              <p className="text-lg">Select an email to view details</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Mailbox;