import React from "react";
import { Link } from "react-router-dom";

const Career = () => {
  return (
    <>
      <div className="relative overflow-hidden">
        <div
          aria-hidden="true"
          className="flex absolute  start-1/2 transform -translate-x-1/2"
        >
          <div className="bg-gradient-to-r from-violet-300/50 to-purple-100 blur-3xl w-[25rem] h-[44rem] rotate-[-60deg] transform -translate-x-[10rem]" />
          <div className="bg-gradient-to-tl from-blue-50 via-blue-100 to-blue-50 blur-3xl w-[90rem] h-[50rem] rounded-fulls origin-top-left -rotate-12 -translate-x-[15rem]" />
        </div>
        {/* End Gradients */}
        <div className="relative animate-on-scroll -mt-56 z-10">
          {/* Opening Position */}
          <section className="max-w-[80rem] mx-auto mt-32 px-4 sm:px-6 lg:px-8 py-10 lg:py-16">
            <div className="container mx-auto px-4">
              <div className="relative animate-on-scroll z-10">
                <div className="max-w-[80rem] mx-auto mt-32 px-4 sm:px-6 lg:px-8 py-10 lg:py-16">
                  <div className="max-w-[80rem] text-start mx-auto">
                    <p className="inline-block text-sm font-medium bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent">
                      BZSoftech: A vision of the future!
                    </p>
                    {/* Title */}
                    <div className="flex items-end justify-between mt-5 max-w-2xl">
                      <h1 className="block font-light logo text-gray-800 text-4xl md:text-5xl lg:text-6xl">
                        Careers
                      </h1>
                    </div>
                    {/* End Title */}
                    <div className="mt-5 max-w-3xl">
                      <p className="text-lg text-gray-600 text-justify">
                        At BZ Softech, we’re revolutionizing technology to
                        enhance connectivity and collaboration. Our mission is
                        to drive efficiency and innovation in the digital world.
                        By 2030, we aim to empower 10,000 visionaries to lead
                        the future of software excellence.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap mb-16 mx-2 -mt-8 text-justify">
                <div className="w-full lg:w-2/3 p-5">
                  <p className="text-gray-600">
                    At BzSoftech, we understand that the modern workplace is
                    evolving rapidly, with teams becoming increasingly
                    distributed and collaboration becoming more complex. Many
                    organizations struggle with scattered workflows, making it
                    difficult to keep track of progress and align on the bigger
                    picture. At BzSoftech, we are developing innovative
                    solutions that streamline collaboration, offering a unified
                    platform to help teams coordinate effortlessly, stay
                    aligned, and gain real-time insights into their projects.
                  </p>
                </div>
                <div className="w-full lg:w-1/3 p-4">
                  <p className="text-gray-600">
                    Our mission is to simplify work processes, empowering teams
                    to focus on what truly matters—delivering impactful results.
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap mb-4 -mx-4">
                <div className="w-full lg:w-2/3 p-4">
                  <img
                    className="w-full object-cover rounded-3xl"
                    src="https://img.freepik.com/free-photo/business-meeting-scene_23-2147626524.jpg?ga=GA1.1.1469234575.1726214580&semt=ais_hybrid"
                    alt=""
                  />
                </div>
                <div className="w-full lg:w-1/3 p-4">
                  <img
                    className="w-full object-cover rounded-3xl"
                    style={{ height: 550 }}
                    src="https://img.freepik.com/premium-photo/back-view-businessman-climbing-stairs-male-head-silhouette-light-background-success-concept-double-exposure_670147-63971.jpg?ga=GA1.1.1469234575.1726214580&semt=ais_hybrid"
                    alt=""
                  />
                </div>
              </div>

              <h2 className="text-3xl font-bold font-heading mb-4">Benefits</h2>
              <p className="text-gray-600 mb-16">
                We help our employees thrive
              </p>
              <div className="flex flex-wrap mb-24 -mx-4">
                <div className="w-full md:w-1/2 lg:w-1/3 p-4">
                  <div className="ml-8 w-12 h-12 rounded-full bg-blue-500 flex items-center justify-center mb-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M7.65006 20.91C7.62006 20.91 7.58006 20.93 7.55006 20.93C5.61006 19.97 4.03006 18.38 3.06006 16.44C3.06006 16.41 3.08006 16.37 3.08006 16.34C4.30006 16.7 5.56006 16.97 6.81006 17.18C7.03006 18.44 7.29006 19.69 7.65006 20.91Z"
                        fill="white"
                      />
                      <path
                        d="M17.6334 17.613C18.4251 17.4736 19.2106 17.3092 19.9897 17.1125C19.2485 18.2662 18.2589 19.2503 17.0998 19.9947C17.3092 19.207 17.4911 18.4129 17.6334 17.613Z"
                        fill="white"
                        stroke="white"
                      />
                      <path
                        d="M21.02 7.70998C19.76 7.32998 18.49 7.01998 17.2 6.79998C16.99 5.50998 16.68 4.22998 16.29 2.97998C18.36 3.96998 20.03 5.63998 21.02 7.70998Z"
                        fill="white"
                      />
                      <path
                        d="M7.64998 3.09006C7.28998 4.31006 7.02998 5.55006 6.81998 6.81006C5.52998 7.01006 4.24998 7.33006 2.97998 7.71006C3.94998 5.70006 5.55998 4.05006 7.54998 3.06006C7.57998 3.06006 7.61998 3.09006 7.64998 3.09006Z"
                        fill="white"
                      />
                      <path
                        d="M15.49 6.59C13.17 6.33 10.83 6.33 8.51001 6.59C8.76001 5.22 9.08001 3.85 9.53001 2.53C9.55001 2.45 9.54001 2.39 9.55001 2.31C10.34 2.12 11.15 2 12 2C12.84 2 13.66 2.12 14.44 2.31C14.45 2.39 14.45 2.45 14.47 2.53C14.92 3.86 15.24 5.22 15.49 6.59Z"
                        fill="white"
                      />
                      <path
                        d="M6.59 15.49C5.21 15.24 3.85 14.92 2.53 14.47C2.45 14.45 2.39 14.46 2.31 14.45C2.12 13.66 2 12.85 2 12C2 11.16 2.12 10.34 2.31 9.56001C2.39 9.55001 2.45 9.55001 2.53 9.53001C3.86 9.09001 5.21 8.76001 6.59 8.51001C6.34 10.83 6.34 13.17 6.59 15.49Z"
                        fill="white"
                      />
                      <path
                        d="M22 12C22 12.85 21.88 13.66 21.69 14.45C21.61 14.46 21.55 14.45 21.47 14.47C20.14 14.91 18.78 15.24 17.41 15.49C17.67 13.17 17.67 10.83 17.41 8.51001C18.78 8.76001 20.15 9.08001 21.47 9.53001C21.55 9.55001 21.61 9.56001 21.69 9.56001C21.88 10.35 22 11.16 22 12Z"
                        fill="white"
                      />
                      <path
                        d="M15.49 17.41C15.24 18.79 14.92 20.15 14.47 21.47C14.45 21.55 14.45 21.61 14.44 21.69C13.66 21.88 12.84 22 12 22C11.15 22 10.34 21.88 9.55001 21.69C9.54001 21.61 9.55001 21.55 9.53001 21.47C9.09001 20.14 8.76001 18.79 8.51001 17.41C9.67001 17.54 10.83 17.63 12 17.63C13.17 17.63 14.34 17.54 15.49 17.41Z"
                        fill="white"
                      />
                      <path
                        d="M15.7633 15.7633C13.2622 16.0789 10.7378 16.0789 8.23667 15.7633C7.92111 13.2622 7.92111 10.7378 8.23667 8.23667C10.7378 7.92111 13.2622 7.92111 15.7633 8.23667C16.0789 10.7378 16.0789 13.2622 15.7633 15.7633Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="relative">
                    <div className="absolute top-0 left-0 bg-blue-500 w-0.5 h-6" />
                    <div className="pl-8">
                      <h2 className="text-xl font-bold font-heading mb-4">
                        Flexible Working Style
                      </h2>
                      <p className="text-gray-600">
                        We're fully remote, collaborating across time zones.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-1/2 lg:w-1/3 p-4">
                  <div className="ml-8 w-12 h-12 rounded-full bg-blue-500 flex items-center justify-center mb-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11.97 2C6.44997 2 1.96997 6.48 1.96997 12C1.96997 17.52 6.44997 22 11.97 22C17.49 22 21.97 17.52 21.97 12C21.97 6.48 17.5 2 11.97 2ZM17.4 16.43C16.17 18.2 14.15 19.25 12 19.25C9.84997 19.25 7.82997 18.2 6.59997 16.43C6.35997 16.09 6.44997 15.62 6.78997 15.39C7.12997 15.15 7.59997 15.24 7.82997 15.58C8.77997 16.94 10.34 17.76 12 17.76C13.66 17.76 15.22 16.95 16.17 15.58C16.41 15.24 16.87 15.16 17.21 15.39C17.56 15.62 17.64 16.09 17.4 16.43Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="relative">
                    <div className="absolute top-0 left-0 bg-blue-500 w-0.5 h-6" />
                    <div className="pl-8">
                      <h2 className="text-xl font-bold font-heading mb-4">
                        Balanced Working Life
                      </h2>
                      <p className="text-gray-600">
                        Enjoy flexible 4-week vacations. Recharge as needed.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-full md:w-1/2 lg:w-1/3 p-4">
                  <div className="ml-8 w-12 h-12 rounded-full bg-purple-900 flex items-center justify-center mb-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M17.56 2H6.41C3.98 2 2 3.98 2 6.41V12.91V13.11C2 15.55 3.98 17.52 6.41 17.52H10.25C10.8 17.52 11.25 17.97 11.25 18.52V19.49C11.25 20.04 10.8 20.49 10.25 20.49H7.83C7.42 20.49 7.08 20.83 7.08 21.24C7.08 21.65 7.41 22 7.83 22H16.18C16.59 22 16.93 21.66 16.93 21.25C16.93 20.84 16.59 20.5 16.18 20.5H13.76C13.21 20.5 12.76 20.05 12.76 19.5V18.53C12.76 17.98 13.21 17.53 13.76 17.53H17.57C20.01 17.53 21.98 15.55 21.98 13.12V12.92V6.42C21.97 3.98 19.99 2 17.56 2Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="relative">
                    <div className="absolute top-0 left-0 bg-blue-500 w-0.5 h-6" />
                    <div className="pl-8">
                      <h2 className="text-xl font-bold font-heading mb-4">
                        Generous Perks Program
                      </h2>
                      <p className="text-gray-600">
                        We offer stipends for home office, learning, and
                        wellness perks.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <h2 className="text-3xl font-bold font-heading mb-10">
                Open roles
              </h2>
              <p className="text-lg font-bold font-heading mb-4">Engineering</p>
              <Link
                className="p-8 rounded-3xl border border-gray-100 flex items-center justify-between flex-wrap gap-4 mb-10 hover:border-blue-500 transition duration-200"
                to="/contact"
              >
                <div>
                  <p className="text-lg font-bold font-heading mb-2">
                    Full-Stack Engineer
                  </p>
                  <div className="flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M17.1833 7.04158C16.3083 3.19159 12.95 1.45825 9.99996 1.45825C9.99996 1.45825 9.99996 1.45825 9.99162 1.45825C7.04996 1.45825 3.68329 3.18325 2.80829 7.03325C1.83329 11.3333 4.46662 14.9749 6.84996 17.2666C7.73329 18.1166 8.86662 18.5416 9.99996 18.5416C11.1333 18.5416 12.2666 18.1166 13.1416 17.2666C15.525 14.9749 18.1583 11.3416 17.1833 7.04158ZM9.99996 11.2166C8.54996 11.2166 7.37496 10.0416 7.37496 8.59159C7.37496 7.14158 8.54996 5.96658 9.99996 5.96658C11.45 5.96658 12.625 7.14158 12.625 8.59159C12.625 10.0416 11.45 11.2166 9.99996 11.2166Z"
                        fill="#8C8C8C"
                      />
                    </svg>
                    <span className="text-gray-500">United Plaza, FSD, Punjab</span>
                  </div>
                </div>
                <div className="flex flex-wrap gap-2">
                  <div className="px-2 py-1 rounded-lg border border-blue-100 bg-blue-50 flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M4.10964 11.8857C4.17411 12.2351 4.24439 12.5847 4.32337 12.9332C3.84585 12.577 3.42135 12.1516 3.06418 11.6726C3.41234 11.7521 3.76165 11.8227 4.10964 11.8857Z"
                        fill="#3B82F6"
                        stroke="#3B82F6"
                      />
                      <path
                        d="M13.96 10.9666C13.3 12.2933 12.2 13.3666 10.86 14.0133C11.1133 13.1666 11.3267 12.3133 11.4667 11.4533C12.3067 11.3133 13.1333 11.1333 13.9467 10.8933C13.94 10.92 13.96 10.9466 13.96 10.9666Z"
                        fill="#3B82F6"
                      />
                      <path
                        d="M14.0133 5.13991C13.1733 4.88657 12.3267 4.67991 11.4667 4.53324C11.3267 3.67324 11.12 2.81991 10.86 1.98657C12.24 2.64657 13.3533 3.75991 14.0133 5.13991Z"
                        fill="#3B82F6"
                      />
                      <path
                        d="M5.10003 2.06004C4.86003 2.87337 4.68669 3.70004 4.54669 4.54004C3.68669 4.67337 2.83336 4.88671 1.98669 5.14004C2.63336 3.80004 3.70669 2.70004 5.03336 2.04004C5.05336 2.04004 5.08003 2.06004 5.10003 2.06004Z"
                        fill="#3B82F6"
                      />
                      <path
                        d="M10.3267 4.39325C8.78001 4.21992 7.22001 4.21992 5.67334 4.39325C5.84001 3.47992 6.05334 2.56659 6.35334 1.68659C6.36667 1.63325 6.36001 1.59325 6.36667 1.53992C6.89334 1.41325 7.43334 1.33325 8.00001 1.33325C8.56001 1.33325 9.10667 1.41325 9.62667 1.53992C9.63334 1.59325 9.63334 1.63325 9.64667 1.68659C9.94667 2.57325 10.16 3.47992 10.3267 4.39325Z"
                        fill="#3B82F6"
                      />
                      <path
                        d="M4.39337 10.3267C3.47337 10.16 2.56671 9.94667 1.68671 9.64667C1.63337 9.63334 1.59337 9.64001 1.54004 9.63334C1.41337 9.10667 1.33337 8.56667 1.33337 8.00001C1.33337 7.44001 1.41337 6.89334 1.54004 6.37334C1.59337 6.36667 1.63337 6.36667 1.68671 6.35334C2.57337 6.06001 3.47337 5.84001 4.39337 5.67334C4.22671 7.22001 4.22671 8.78001 4.39337 10.3267Z"
                        fill="#3B82F6"
                      />
                      <path
                        d="M14.6667 8.00001C14.6667 8.56667 14.5867 9.10667 14.46 9.63334C14.4067 9.64001 14.3667 9.63334 14.3134 9.64667C13.4267 9.94001 12.52 10.16 11.6067 10.3267C11.78 8.78001 11.78 7.22001 11.6067 5.67334C12.52 5.84001 13.4334 6.05334 14.3134 6.35334C14.3667 6.36667 14.4067 6.37334 14.46 6.37334C14.5867 6.90001 14.6667 7.44001 14.6667 8.00001Z"
                        fill="#3B82F6"
                      />
                      <path
                        d="M10.3267 11.6067C10.16 12.5267 9.94667 13.4334 9.64667 14.3134C9.63334 14.3667 9.63334 14.4067 9.62667 14.46C9.10667 14.5867 8.56001 14.6667 8.00001 14.6667C7.43334 14.6667 6.89334 14.5867 6.36667 14.46C6.36001 14.4067 6.36667 14.3667 6.35334 14.3134C6.06001 13.4267 5.84001 12.5267 5.67334 11.6067C6.44667 11.6934 7.22001 11.7534 8.00001 11.7534C8.78001 11.7534 9.56001 11.6934 10.3267 11.6067Z"
                        fill="#3B82F6"
                      />
                      <path
                        d="M10.5089 10.5088C8.84153 10.7192 7.15855 10.7192 5.49115 10.5088C5.28078 8.84141 5.28078 7.15843 5.49115 5.49103C7.15855 5.28066 8.84153 5.28066 10.5089 5.49103C10.7193 7.15843 10.7193 8.84141 10.5089 10.5088Z"
                        fill="#3B82F6"
                      />
                    </svg>
                    <span className="text-blue-500 text-sm font-medium">
                      Remote
                    </span>
                  </div>
                  <div className="px-2 py-1 rounded-lg border border-blue-100 bg-blue-50 text-blue-500 text-sm font-medium">
                    Full time
                  </div>
                </div>
              </Link>
              <p className="text-lg font-bold font-heading mb-4">Design</p>
              <Link 
                className="p-8 rounded-3xl border border-gray-100 flex items-center justify-between flex-wrap gap-4 mb-10 hover:border-blue-500 transition duration-200"
                to="/contact"
              >
                <div>
                  <p className="text-lg font-bold font-heading mb-2">
                    Staff Product Designer
                  </p>
                  <div className="flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M17.1833 7.04158C16.3083 3.19159 12.95 1.45825 9.99996 1.45825C9.99996 1.45825 9.99996 1.45825 9.99162 1.45825C7.04996 1.45825 3.68329 3.18325 2.80829 7.03325C1.83329 11.3333 4.46662 14.9749 6.84996 17.2666C7.73329 18.1166 8.86662 18.5416 9.99996 18.5416C11.1333 18.5416 12.2666 18.1166 13.1416 17.2666C15.525 14.9749 18.1583 11.3416 17.1833 7.04158ZM9.99996 11.2166C8.54996 11.2166 7.37496 10.0416 7.37496 8.59159C7.37496 7.14158 8.54996 5.96658 9.99996 5.96658C11.45 5.96658 12.625 7.14158 12.625 8.59159C12.625 10.0416 11.45 11.2166 9.99996 11.2166Z"
                        fill="#8C8C8C"
                      />
                    </svg>
                    <span className="text-gray-500">United Plaza, FSD, Punjab</span>
                  </div>
                </div>
                <div className="flex flex-wrap gap-2">
                  <div className="px-2 py-1 rounded-lg border border-blue-100 bg-blue-50 flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M4.10964 11.8857C4.17411 12.2351 4.24439 12.5847 4.32337 12.9332C3.84585 12.577 3.42135 12.1516 3.06418 11.6726C3.41234 11.7521 3.76165 11.8227 4.10964 11.8857Z"
                        fill="#3B82F6"
                        stroke="#3B82F6"
                      />
                      <path
                        d="M13.96 10.9666C13.3 12.2933 12.2 13.3666 10.86 14.0133C11.1133 13.1666 11.3267 12.3133 11.4667 11.4533C12.3067 11.3133 13.1333 11.1333 13.9467 10.8933C13.94 10.92 13.96 10.9466 13.96 10.9666Z"
                        fill="#3B82F6"
                      />
                      <path
                        d="M14.0133 5.13991C13.1733 4.88657 12.3267 4.67991 11.4667 4.53324C11.3267 3.67324 11.12 2.81991 10.86 1.98657C12.24 2.64657 13.3533 3.75991 14.0133 5.13991Z"
                        fill="#3B82F6"
                      />
                      <path
                        d="M5.10003 2.06004C4.86003 2.87337 4.68669 3.70004 4.54669 4.54004C3.68669 4.67337 2.83336 4.88671 1.98669 5.14004C2.63336 3.80004 3.70669 2.70004 5.03336 2.04004C5.05336 2.04004 5.08003 2.06004 5.10003 2.06004Z"
                        fill="#3B82F6"
                      />
                      <path
                        d="M10.3267 4.39325C8.78001 4.21992 7.22001 4.21992 5.67334 4.39325C5.84001 3.47992 6.05334 2.56659 6.35334 1.68659C6.36667 1.63325 6.36001 1.59325 6.36667 1.53992C6.89334 1.41325 7.43334 1.33325 8.00001 1.33325C8.56001 1.33325 9.10667 1.41325 9.62667 1.53992C9.63334 1.59325 9.63334 1.63325 9.64667 1.68659C9.94667 2.57325 10.16 3.47992 10.3267 4.39325Z"
                        fill="#3B82F6"
                      />
                      <path
                        d="M4.39337 10.3267C3.47337 10.16 2.56671 9.94667 1.68671 9.64667C1.63337 9.63334 1.59337 9.64001 1.54004 9.63334C1.41337 9.10667 1.33337 8.56667 1.33337 8.00001C1.33337 7.44001 1.41337 6.89334 1.54004 6.37334C1.59337 6.36667 1.63337 6.36667 1.68671 6.35334C2.57337 6.06001 3.47337 5.84001 4.39337 5.67334C4.22671 7.22001 4.22671 8.78001 4.39337 10.3267Z"
                        fill="#3B82F6"
                      />
                      <path
                        d="M14.6667 8.00001C14.6667 8.56667 14.5867 9.10667 14.46 9.63334C14.4067 9.64001 14.3667 9.63334 14.3134 9.64667C13.4267 9.94001 12.52 10.16 11.6067 10.3267C11.78 8.78001 11.78 7.22001 11.6067 5.67334C12.52 5.84001 13.4334 6.05334 14.3134 6.35334C14.3667 6.36667 14.4067 6.37334 14.46 6.37334C14.5867 6.90001 14.6667 7.44001 14.6667 8.00001Z"
                        fill="#3B82F6"
                      />
                      <path
                        d="M10.3267 11.6067C10.16 12.5267 9.94667 13.4334 9.64667 14.3134C9.63334 14.3667 9.63334 14.4067 9.62667 14.46C9.10667 14.5867 8.56001 14.6667 8.00001 14.6667C7.43334 14.6667 6.89334 14.5867 6.36667 14.46C6.36001 14.4067 6.36667 14.3667 6.35334 14.3134C6.06001 13.4267 5.84001 12.5267 5.67334 11.6067C6.44667 11.6934 7.22001 11.7534 8.00001 11.7534C8.78001 11.7534 9.56001 11.6934 10.3267 11.6067Z"
                        fill="#3B82F6"
                      />
                      <path
                        d="M10.5089 10.5088C8.84153 10.7192 7.15855 10.7192 5.49115 10.5088C5.28078 8.84141 5.28078 7.15843 5.49115 5.49103C7.15855 5.28066 8.84153 5.28066 10.5089 5.49103C10.7193 7.15843 10.7193 8.84141 10.5089 10.5088Z"
                        fill="#3B82F6"
                      />
                    </svg>
                    <span className="text-blue-500 text-sm font-medium">
                      Remote
                    </span>
                  </div>
                  <div className="px-2 py-1 rounded-lg border border-blue-100 bg-blue-50 text-blue-500 text-sm font-medium">
                    Full time
                  </div>
                </div>
              </Link>
              <div className="rounded-3xl border border-blue-500 p-6 flex items-center justify-between flex-wrap gap-4">
                <p className="text-gray-600 max-w-xl">
                  <span>Can’t find the right role? Email your CV to </span>
                  <span className="font-semibold"> career@bzsoftech.com</span>
                  <span>
                    {" "}
                    to be considered for new positions in the future.
                  </span>
                </p>
                <Link
                  className="w-full sm:w-auto py-3 px-5 h-12 rounded-full bg-blue-500 border border-blue-600 shadow hover:bg-blue-600 focus:ring focus:ring-blue-200 transition duration-200 flex items-center justify-center gap-2"
                  to="/contact"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M14.1666 2.91675H5.83329C3.33329 2.91675 1.66663 4.16675 1.66663 7.08341V12.9167C1.66663 15.8334 3.33329 17.0834 5.83329 17.0834H14.1666C16.6666 17.0834 18.3333 15.8334 18.3333 12.9167V7.08341C18.3333 4.16675 16.6666 2.91675 14.1666 2.91675ZM14.5583 7.99175L11.95 10.0751C11.4 10.5167 10.7 10.7334 9.99996 10.7334C9.29996 10.7334 8.59163 10.5167 8.04996 10.0751L5.44163 7.99175C5.17496 7.77508 5.13329 7.37508 5.34163 7.10841C5.55829 6.84175 5.94996 6.79175 6.21663 7.00841L8.82496 9.09175C9.45829 9.60008 10.5333 9.60008 11.1666 9.09175L13.775 7.00841C14.0416 6.79175 14.4416 6.83341 14.65 7.10841C14.8666 7.37508 14.825 7.77508 14.5583 7.99175Z"
                      fill="white"
                    />
                  </svg>
                  <span className="text-sm font-semibold text-white">
                    Email my resume
                  </span>
                </Link>
              </div>
            </div>
          </section>
        </div>
      </div>
      {/* End Gradients */}
    </>
  );
};

export default Career;
