import React, { useState } from "react";
import { MapPin } from "lucide-react";
import axios from 'axios';
import {Link} from 'react-router-dom'
import { toast, Toaster } from 'react-hot-toast';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    eMail: '',
    message: ''
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    }
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.firstName.trim()) newErrors.firstName = 'First name is required';
    if (!formData.lastName.trim()) newErrors.lastName = 'Last name is required';
    if (!formData.eMail.trim()) {
      newErrors.eMail = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.eMail)) {
      newErrors.eMail = 'Email is invalid';
    }
    if (!formData.message.trim()) newErrors.message = 'Message is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await axios.post('https://bzs-server.vercel.app/mail/postmail', formData);
        toast.success('Message sent successfully!', { position: 'top-center' });
        setFormData({ firstName: '', lastName: '', eMail: '', message: '' });
      } catch (error) {
        toast.error('Failed to send message. Please try again.', { position: 'top-center' });
      }
    } else {
      toast.error('Please fill in all required fields.', { position: 'top-center' });
    }
  };

  return (
    <>
      <Toaster />
      <div className="relative overflow-hidden">
       {/* Gradients */}
       <div
          aria-hidden="true"
          className="flex absolute -top-96 start-1/2 transform -translate-x-1/2"
        >
          <div className="bg-gradient-to-r from-violet-300/50 to-purple-100 blur-3xl w-[25rem] h-[44rem] rotate-[-60deg] transform -translate-x-[10rem]" />
          <div className="bg-gradient-to-tl from-blue-50 via-blue-100 to-blue-50 blur-3xl w-[90rem] h-[50rem] rounded-fulls origin-top-left -rotate-12 -translate-x-[15rem]" />
        </div>
        {/* End Gradients */}
        <div className="relative z-10 animate-on-scroll">
          <div className="max-w-[80rem] mx-auto mt-32 px-4 sm:px-6 lg:px-8 py-10 lg:py-16">
            <div className="max-w-[80rem] text-start mx-auto">
              <p className="inline-block text-sm font-medium bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent">
                BZSoftech: A vision of the future!
              </p>
              {/* Title */}
              <div className="mt-5 max-w-2xl">
                <h1 className="block font-light logo text-gray-800 text-4xl md:text-5xl lg:text-6xl">
                  Contact Us
                </h1>
              </div>
              {/* End Title */}
              <div className="mt-5 max-w-3xl">
                <p className="text-lg text-gray-600 text-justify">
                  We're here to help you take the next step toward digital
                  transformation. Whether you have questions about our services,
                  need expert advice, or want to explore how we can tailor
                  solutions to meet your business needs, our team is ready to
                  assist. Reach out to us through any of our contact channels,
                  and we'll ensure a prompt and personalized response to guide
                  you toward achieving your goals in today's fast-paced digital
                  environment. Let's connect and work together to drive your
                  success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="overflow-hidden max-w-[80rem] -mt-16 mx-auto ">
        <div className="container px-4 mx-auto pt-16 pb-20">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full lg:w-1/2 p-4">
              <div className="max-w-xl mx-auto">
              <div className="max-w-xl mx-auto">
                <div className="mb-10 flex items-center gap-1">
                  <div className="rounded-3xl h-px w-3" />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={3}
                    height={3}
                    viewBox="0 0 3 3"
                    fill="none"
                  >
                    <circle cx="1.5" cy="1.5" r="1.5" fill="#FFA632" />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={3}
                    height={3}
                    viewBox="0 0 3 3"
                    fill="none"
                  >
                    <circle cx="1.5" cy="1.5" r="1.5" fill="#FFA632" />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={3}
                    height={3}
                    viewBox="0 0 3 3"
                    fill="none"
                  >
                    <circle cx="1.5" cy="1.5" r="1.5" fill="#FFA632" />
                  </svg>
                  <div className="bg--200 rounded-3xl h-px w-3" />
                </div>
                <div className="flex items-center flex-wrap gap-3 mb-4">
                  <div className="bg-blue-500 rounded-xl p-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M18.3333 17.7083H1.66666C1.32499 17.7083 1.04166 17.9916 1.04166 18.3333C1.04166 18.675 1.32499 18.9583 1.66666 18.9583H18.3333C18.675 18.9583 18.9583 18.675 18.9583 18.3333C18.9583 17.9916 18.675 17.7083 18.3333 17.7083Z"
                        fill="white"
                      />
                      <path
                        d="M14.1667 1.66669H5.83333C3.33333 1.66669 2.5 3.15835 2.5 5.00002V18.3334H17.5V5.00002C17.5 3.15835 16.6667 1.66669 14.1667 1.66669ZM8.33333 14.375H5.83333C5.49167 14.375 5.20833 14.0917 5.20833 13.75C5.20833 13.4084 5.49167 13.125 5.83333 13.125H8.33333C8.675 13.125 8.95833 13.4084 8.95833 13.75C8.95833 14.0917 8.675 14.375 8.33333 14.375ZM8.33333 10.625H5.83333C5.49167 10.625 5.20833 10.3417 5.20833 10C5.20833 9.65835 5.49167 9.37502 5.83333 9.37502H8.33333C8.675 9.37502 8.95833 9.65835 8.95833 10C8.95833 10.3417 8.675 10.625 8.33333 10.625ZM8.33333 6.87502H5.83333C5.49167 6.87502 5.20833 6.59169 5.20833 6.25002C5.20833 5.90835 5.49167 5.62502 5.83333 5.62502H8.33333C8.675 5.62502 8.95833 5.90835 8.95833 6.25002C8.95833 6.59169 8.675 6.87502 8.33333 6.87502ZM14.1667 14.375H11.6667C11.325 14.375 11.0417 14.0917 11.0417 13.75C11.0417 13.4084 11.325 13.125 11.6667 13.125H14.1667C14.5083 13.125 14.7917 13.4084 14.7917 13.75C14.7917 14.0917 14.5083 14.375 14.1667 14.375ZM14.1667 10.625H11.6667C11.325 10.625 11.0417 10.3417 11.0417 10C11.0417 9.65835 11.325 9.37502 11.6667 9.37502H14.1667C14.5083 9.37502 14.7917 9.65835 14.7917 10C14.7917 10.3417 14.5083 10.625 14.1667 10.625ZM14.1667 6.87502H11.6667C11.325 6.87502 11.0417 6.59169 11.0417 6.25002C11.0417 5.90835 11.325 5.62502 11.6667 5.62502H14.1667C14.5083 5.62502 14.7917 5.90835 14.7917 6.25002C14.7917 6.59169 14.5083 6.87502 14.1667 6.87502Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <h2 className="text-xl font-bold font-heading">
                    Company Addess
                  </h2>
                </div>
                <p className="text-gray-600 mb-10">
                  Sheikhu-Pura Road United Plaza FaisalAbad, Punjab, Pakistan
                </p>
                <div className="rounded-3xl border  border-gray-200 shadow-sm py-8">
                  <div className="relative">
                    <div className="absolute top-0 left-0 bg-blue-500 w-1 h-8" />
                    <div className="px-8">
                      <h2 className="text-xl font-bold font-heading mb-6">
                        Technical Help desk
                      </h2>
                      <Link
                        className="w-full sm:w-auto h-12 py-3 px-5 rounded-md bg-blue-900 border border-blue-700 hover:bg-blue-800 focus:ring focus:ring-purple-800 transition duration-200 inline-flex items-center justify-center gap-2"
                        to="/"
                      >
                        <span className="text-white text-sm font-semibold">
                          Visit FAQ Center
                        </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={20}
                          height={20}
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M5 10H15.4167M15.4167 10L10.4167 5M15.4167 10L10.4167 15"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="container px-4 mx-auto pt-10">
                  <div className="max-w-4xl mx-auto">
                    <div className="relative w-full h-80 rounded-lg overflow-hidden">
                      <iframe
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        scrolling="no"
                        marginHeight="0"
                        marginWidth="0"
                        src={`https://www.openstreetmap.org/export/embed.html?bbox=${
                          73.15558882698672 - 0.01
                        }%2C${31.46199794224888 - 0.01}%2C${
                          73.15558882698672 + 0.01
                        }%2C${
                          31.46199794224888 + 0.01
                        }&layer=mapnik&marker=${31.46199794224888}%2C${73.15558882698672}`}
                      ></iframe>
                      <div className="absolute inset-0 flex items-center justify-center pointer-events-none"></div>
                    </div>
                  </div>
                  </div>
                  </div>
              </div>
            </div>
            <div className="w-full lg:w-1/2 p-4">
              <form onSubmit={handleSubmit}>
                <div className="border border-gray-200 rounded-3xl p-6 max-w-xl mx-auto">
                  <h2 className="text-3xl text-center font-bold font-heading mb-4">
                    Get in touch
                  </h2>
                  <p className="text-center text-gray-600 mb-16">
                    Fill out the form to connect with a member of our team.
                  </p>
                  <div className="flex flex-wrap -mx-4">
                    <div className="w-full lg:w-1/2 p-4">
                      <label
                        className="text-sm font-medium mb-2 block"
                        htmlFor="firstName"
                      >
                        First name
                      </label>
                      <input
                        className={`w-full rounded-md p-4 outline-none border ${
                          errors.firstName ? 'border-red-500' : 'border-gray-200'
                        } shadow placeholder-gray-500 focus:ring focus:ring-blue-200 transition duration-200`}
                        id="firstName"
                        name="firstName"
                        type="text"
                        placeholder="First name"
                        value={formData.firstName}
                        onChange={handleChange}
                      />
                      {errors.firstName && <p className="text-red-500 text-xs mt-1">{errors.firstName}</p>}
                    </div>
                    <div className="w-full lg:w-1/2 p-4">
                      <label
                        className="text-sm font-medium mb-2 block"
                        htmlFor="lastName"
                      >
                        Last name
                      </label>
                      <input
                        className={`w-full rounded-md p-4 outline-none border ${
                          errors.lastName ? 'border-red-500' : 'border-gray-200'
                        } shadow placeholder-gray-500 focus:ring focus:ring-blue-200 transition duration-200`}
                        id="lastName"
                        name="lastName"
                        type="text"
                        placeholder="Last name"
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                      {errors.lastName && <p className="text-red-500 text-xs mt-1">{errors.lastName}</p>}
                    </div>
                  </div>
                  <label
                    className="text-sm font-medium mb-2 block"
                    htmlFor="eMail"
                  >
                    Email
                  </label>
                  <input
                    className={`w-full mb-4 rounded-md p-4 outline-none border ${
                      errors.eMail ? 'border-red-500' : 'border-gray-200'
                    } shadow placeholder-gray-500 focus:ring focus:ring-blue-200 transition duration-200`}
                    id="eMail"
                    name="eMail"
                    type="email"
                    placeholder="john@email.com"
                    value={formData.eMail}
                    onChange={handleChange}
                  />
                  {errors.eMail && <p className="text-red-500 text-xs mt-1">{errors.eMail}</p>}
                  <label
                    className="text-sm font-medium mb-2 block"
                    htmlFor="message"
                  >
                    Message
                  </label>
                  <textarea
                    className={`resize-none w-full rounded-lg p-4 mb-8 outline-none border ${
                      errors.message ? 'border-red-500' : 'border-gray-200'
                    } placeholder-gray-500 focus:ring focus:ring-blue-200 transition duration-200`}
                    id="message"
                    name="message"
                    rows={5}
                    placeholder="Enter your message"
                    value={formData.message}
                    onChange={handleChange}
                  />
                  {errors.message && <p className="text-red-500 text-xs mt-1">{errors.message}</p>}
                  <button
                    className="py-4 px-6 rounded-md w-full h-14 inline-flex items-center justify-center text-center mb-8 bg-blue-500 border border-blue-600 font-bold font-heading text-white hover:bg-blue-600 focus:ring focus:ring-blue-200 transition duration-200"
                    type="submit"
                  >
                    Submit
                  </button>
                  <p className="text-gray-500 text-sm">
                    <span>
                      We process your information in accordance with our
                    </span>
                    <span className="text-blue-500 text-sm font-semibold ml-1">
                      Privacy Policy
                    </span>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactPage;