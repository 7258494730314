import React from 'react';

function Statistics() {
    return (
        <>
            <section className="max-w-[80rem] px-6 md:px-8 py-16 md:py-32 mx-auto overflow-hidden">
                <div className="container mx-auto">
                    <div className="py-10 md:py-16 px-4 md:px-8 overflow-hidden rounded-3xl cardBG">
                        <div className="max-w-[80rem] mx-auto">
                            <div className="max-w-3xl">
                                <h2 className="logo mb-4 text-xl md:text-2xl lg:text-3xl text-white font-medium tracking-tight">
                                    Why Choose BZSoftech?
                                </h2>
                                <h2 className="font-heading w-full mb-4 text-3xl md:text-4xl lg:text-5xl text-white font-black">
                                    Quality - Reliability - Performance
                                </h2>
                                <p className="mb-6 text-base md:text-lg logo text-gray-200 font-medium">
                                    We are your trusted partner for a diverse range of IT needs, encompassing both IT products and services. Our proven track record of success across diverse industries demonstrates our ability to deliver tailored IT solutions and propel your business forward.
                                </p>
                            </div>
                            <div className="md:max-w-xl">
                                <div className="flex flex-wrap -m-4">
                                    <div className="w-full md:w-1/2 p-4">
                                        <h3 className="font-heading mb-2 text-4xl md:text-5xl text-blue-500 font-black">
                                            15+
                                        </h3>
                                        <p className="text-sm md:text-base text-white font-bold">
                                            Years of Experience
                                        </p>
                                    </div>
                                    <div className="w-full md:w-1/2 p-4">
                                        <h3 className="font-heading mb-2 text-4xl md:text-5xl text-blue-500 font-black">
                                            25+
                                        </h3>
                                        <p className="text-sm md:text-base text-white font-bold">
                                            Team of Experts
                                        </p>
                                    </div>
                                    <div className="w-full md:w-1/2 p-4">
                                        <h3 className="font-heading mb-2 text-4xl md:text-5xl text-blue-500 font-black">
                                            500+
                                        </h3>
                                        <p className="text-sm md:text-base text-white font-bold">
                                            Projects Completed
                                        </p>
                                    </div>
                                    <div className="w-full md:w-1/2 p-4">
                                        <h3 className="font-heading mb-2 text-4xl md:text-5xl text-blue-500 font-black">
                                            99.9%
                                        </h3>
                                        <p className="text-sm md:text-base text-white font-bold">
                                            Client Satisfaction
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Statistics;
