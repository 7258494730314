import React from 'react'

function Stats() {
    return (
        <>
            <div class="flex flex-wrap max-w-[80rem] mx-auto mt-12 ">
                <div class="relative w-full md:w-1/3 px-4 pb-9 md:pb-0 mb-12 lg:mb-0">
                    <div class="hidden md:block absolute top-1/2 right-0 w-px h-28 bg-gray-700 transform -translate-y-1/2"></div>
                    <div class="md:hidden absolute bottom-0 left-1/2 h-px w-40 bg-gray-700 transform -translate-x-1/2"></div>
                    <div class="text-center">
                        <span class="block text-5xl lg:text-7xl font-bold text-gray-700 mb-5">500+</span>
                        <span class="text-xl text-gray-600 uppercase">projects</span>
                    </div>
                </div>
                <div class="relative w-full md:w-1/3 px-4 pb-9 md:pb-0 mb-12 lg:mb-0">
                    <div class="hidden md:block absolute top-1/2 right-0 w-px h-28 bg-gray-700 transform -translate-y-1/2"></div>
                    <div class="md:hidden absolute bottom-0 left-1/2 h-px w-40 bg-gray-700 transform -translate-x-1/2"></div>
                    <div class="text-center">
                        <span class="block text-5xl lg:text-7xl font-bold text-gray-700 mb-5">25+</span>
                        <span class="text-xl text-gray-600 uppercase">Team of Experts</span>
                    </div>
                </div>
                <div class="w-full md:w-1/3 px-4">
                    <div class="text-center">
                        <span class="block text-5xl lg:text-7xl font-bold text-gray-700 mb-5">15+</span>
                        <span class="text-xl text-gray-600 uppercase">years of experience</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Stats
