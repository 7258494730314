import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import star from '../../assets/star-circle.svg';
import wall from '../../assets/wall.png';

const testimonials = [
    {
        text: "Working with BZ Softech has been a game changer for our business. Their expertise in React applications has significantly improved our user experience.",
        name: "Emily Smith",
        position: "Product Manager at Innovatech",
        image: wall,
    },
    {
        text: "BZ Softech delivered an outstanding Android application for us. Their team's professionalism and attention to detail were remarkable.",
        name: "Michael Brown",
        position: "CEO of Mobile Solutions",
        image: wall,
    },
    {
        text: "The ERP system developed by BZ Softech has streamlined our operations. Their support has been invaluable throughout the process.",
        name: "Jessica Davis",
        position: "Operations Director at GreenTech",
        image: wall,
    },
    {
        text: "BZ Softech's team is incredibly talented. They turned our ideas into reality with their custom software solutions, and we couldn't be happier!",
        name: "David Johnson",
        position: "CTO of Future Innovations",
        image: wall,
    },
    {
        text: "I highly recommend BZ Softech for any software development needs. Their dedication and expertise set them apart from the rest.",
        name: "Sophia Williams",
        position: "Marketing Head at Creative Minds",
        image: wall,
    },
];

function Testimonial() {
    const containerRef = useRef(null);
    const animationRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;

        // Function to determine the number of visible cards based on window width
        const updateVisibleCards = () => {
            const width = window.innerWidth;

            // Display one card for small screens, otherwise show 3
            return width < 768 ? 1 : 3; // Adjust 768 to your desired breakpoint
        };

        const totalCards = testimonials.length;
        const visibleCards = updateVisibleCards();
        const cardWidth = container.offsetWidth / visibleCards;

        // Duplicate the cards for seamless scrolling
        const duplicatedTestimonials = [...testimonials, ...testimonials];

        // Create the GSAP animation
        animationRef.current = gsap.to(container, {
            x: `-${cardWidth * totalCards}px`,
            duration: 40,
            ease: "linear",
            repeat: -1,
            paused: false,
        });

        // Pause animation on mouse enter and resume on mouse leave
        const handleMouseEnter = () => {
            animationRef.current.pause();
        };

        const handleMouseLeave = () => {
            animationRef.current.resume();
        };

        // Add event listeners
        container.addEventListener('mouseenter', handleMouseEnter);
        container.addEventListener('mouseleave', handleMouseLeave);

        return () => {
            gsap.killTweensOf(container);
            container.removeEventListener('mouseenter', handleMouseEnter);
            container.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, []);

    return (
        <section className="relative py-10 md:py-20 lg:pt-32 lg:pb-36 overflow-hidden">
            <img
                className="absolute top-0 right-0 mt-6 -mr-24 md:-mr-0"
                src={star}
                alt=""
            />
            <div className="relative container px-4 mx-auto">
                <div className="max-w-7xl px-8 mx-auto">
                    <div className="flex flex-wrap -mx-4 items-center mb-8 md:mb-16">
                        <div className="w-full lg:w-2/3 px-4 mb-8 lg:mb-0">
                            <span className="inline-block py-1 px-3 mb-4 text-xs font-semibold text-blue-900 bg-blue-50 rounded-full">
                                TESTIMONIALS
                            </span>
                            <h1 className="font-heading text-3xl md:text-5xl font-bold text-gray-900 mb-4">
                                <span>What our clients said</span>
                            </h1>
                            <p className="text-gray-500 text-sm md:text-base">
                                Risus viverra justo sagittis vestibulum metus.
                            </p>
                        </div>
                        <div className="w-full lg:w-1/3 px-4">
                            <div className="flex items-center justify-end">
                                {/* Navigation buttons can be added here if needed */}
                            </div>
                        </div>
                    </div>
                    <div className="relative overflow-hidden">
                        <div className="flex" ref={containerRef}>
                            {testimonials.concat(testimonials).map((testimonial, index) => (
                                <div key={index} className="flex-shrink-0 w-full sm:w-full md:w-1/3 p-5">
                                    <div className="flex flex-col justify-between h-full max-w-md mx-auto bg-white border border-slate-200 rounded-lg shadow-lg overflow-hidden">
                                        <div className="p-4 md:p-6">
                                            <div className="flex mb-2 items-center text-yellow-500 text-xl md:text-2xl">
                                                <i className="fa-solid fa-stars"></i>
                                                <i className="fa-solid fa-stars"></i>
                                                <i className="fa-solid fa-stars"></i>
                                                <i className="fa-solid fa-stars"></i>
                                                <i className="fa-solid fa-stars"></i>
                                            </div>
                                            <p className="text-base md:text-lg text-gray-900">
                                                {testimonial.text}
                                            </p>
                                        </div>
                                        <div className="px-4 py-3 md:px-8 md:py-4 bg-blue-800">
                                            <div className="flex items-center">
                                                <img
                                                    className="block w-10 h-10 rounded-full"
                                                    src={testimonial.image}
                                                    alt={testimonial.name}
                                                />
                                                <div className="ml-3">
                                                    <span className="block text-lg md:text-xl text-white font-semibold leading-none">
                                                        {testimonial.name}
                                                    </span>
                                                    <span className="block text-xs md:text-sm text-blue-200">
                                                        {testimonial.position}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="absolute inset-0 pointer-events-none">
                            <div className="absolute top-0 left-0 w-1/6 h-full bg-gradient-to-r from-white via-transparent"></div>
                            <div className="absolute top-0 right-0 w-1/6 h-full bg-gradient-to-l from-white via-transparent"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;
