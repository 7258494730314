import React, { useEffect } from "react";
import Testimonial from "./Testimonial";
import Stats from "./Stats";
import VisMis from "./VisMis";
import CEOMsg from "./CEOMsg";
import Team from "./Team";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function About() {
  useEffect(() => {
    // Setup ScrollTrigger animations
    const sections = gsap.utils.toArray(".animate-on-scroll");

    sections.forEach((section) => {
      gsap.fromTo(
        section,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          scrollTrigger: {
            trigger: section,
            start: "top 80%",
            end: "bottom top",
            toggleActions: "play none none reverse",
          },
        }
      );
    });

    // Cleanup function
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <div>
      <div className="">
        <div className="relative overflow-hidden">
          {/* Gradients */}
          <div
            aria-hidden="true"
            className="flex absolute -top-96 start-1/2 transform -translate-x-1/2"
          >
            <div className="bg-gradient-to-r from-violet-300/50 to-purple-100 blur-3xl w-[25rem] h-[44rem] rotate-[-60deg] transform -translate-x-[10rem]" />
            <div className="bg-gradient-to-tl from-blue-50 via-blue-100 to-blue-50 blur-3xl w-[90rem] h-[50rem] rounded-fulls origin-top-left -rotate-12 -translate-x-[15rem]" />
          </div>
          {/* End Gradients */}
          <div className="relative animate-on-scroll z-10">
            <div className="max-w-[80rem] mx-auto mt-32 px-4 sm:px-6 lg:px-8 py-10 lg:py-16">
              <div className="max-w-[80rem] text-start mx-auto">
                <p className="inline-block text-sm font-medium bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent">
                  BZSoftech: A vision of the future!
                </p>
                {/* Title */}
                <div className="flex items-end justify-between mt-5 max-w-2xl">
                  <h1 className="block font-light logo text-gray-800 text-4xl md:text-5xl lg:text-6xl">
                    About Us
                  </h1>
                  <div class="text-center">
                    {/* <a
                      class="relative group inline-block py-2 px-6 text-blue-50 font-semibold bg-blue-800 rounded-full overflow-hidden"
                      href="#"
                    >
                      <div class="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                      <div class="relative flex items-center justify-center">
                        <span class="mr-4 text-sm">Portfolio</span>
                        <span>
                          <svg
                            width="8"
                            height="12"
                            viewbox="0 0 8 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.83 5.28999L2.59 1.04999C2.49704 0.956266 2.38644 0.881872 2.26458 0.831103C2.14272 0.780334 2.01202 0.754196 1.88 0.754196C1.74799 0.754196 1.61729 0.780334 1.49543 0.831103C1.37357 0.881872 1.26297 0.956266 1.17 1.04999C0.983753 1.23736 0.879211 1.49081 0.879211 1.75499C0.879211 2.01918 0.983753 2.27263 1.17 2.45999L4.71 5.99999L1.17 9.53999C0.983753 9.72736 0.879211 9.98081 0.879211 10.245C0.879211 10.5092 0.983753 10.7626 1.17 10.95C1.26344 11.0427 1.37426 11.116 1.4961 11.1658C1.61794 11.2155 1.7484 11.2408 1.88 11.24C2.01161 11.2408 2.14207 11.2155 2.26391 11.1658C2.38575 11.116 2.49656 11.0427 2.59 10.95L6.83 6.70999C6.92373 6.61703 6.99813 6.50643 7.04889 6.38457C7.09966 6.26271 7.1258 6.13201 7.1258 5.99999C7.1258 5.86798 7.09966 5.73728 7.04889 5.61542C6.99813 5.49356 6.92373 5.38296 6.83 5.28999Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </div>
                    </a> */}
                  </div>
                </div>
                {/* End Title */}
                <div className="mt-5 max-w-3xl">
                  <p className="text-lg text-justify text-gray-600">
                    At BZ Softech, we’re revolutionizing technology to enhance
                    connectivity and collaboration. Our mission is to drive
                    efficiency and innovation in the digital world. By 2030, we
                    aim to empower 10,000 visionaries to lead the future of
                    software excellence.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Stats></Stats>
      </div>
      <div className="animate-on-scroll mx-4">
        <VisMis></VisMis>
      </div>
      <div className="animate-on-scroll">
        <CEOMsg></CEOMsg>
      </div>
      <div className="animate-on-scroll">
        <Team></Team>
      </div>
      <div className="animate-on-scroll">
        <Testimonial></Testimonial>
      </div>
    </div>
  );
}

export default About;
