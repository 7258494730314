import React from 'react'
import { Link } from 'react-router-dom'

function Support() {
    return (
        <div>
            <div className="relative overflow-hidden">
                {/* Gradients */}
                <div
                    aria-hidden="true"
                    className="flex absolute -top-96 start-1/2 transform -translate-x-1/2"
                >
                    <div className="bg-gradient-to-r from-violet-300/50 to-purple-100 blur-3xl w-[25rem] h-[44rem] rotate-[-60deg] transform -translate-x-[10rem]" />
                    <div className="bg-gradient-to-tl from-blue-50 via-blue-100 to-blue-50 blur-3xl w-[90rem] h-[50rem] rounded-fulls origin-top-left -rotate-12 -translate-x-[15rem]" />
                </div>
                {/* End Gradients */}
                <div className="relative z-10">
                    <div className="max-w-[80rem] mx-auto mt-32 px-4 sm:px-6 lg:px-8 py-10 lg:py-16">
                        <div className="max-w-[80rem] text-start mx-auto">
                            <div class="flex items-center flex-wrap gap-4">
                                <Link class="inline-block text-sm font-medium tracking-tight bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent" to="/">Home</Link>
                                <svg xmlns="http://www.w3.org/2000/svg" width="4" height="8" viewbox="0 0 4 8" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.50225 0.5L0 0.98755L2.95244 4L0.0073503 7.0051L0.50225 7.5L4.00225 4L0.50225 0.5Z" fill="#19191B"></path>
                                </svg>
                                <Link class="inline-block text-sm font-medium tracking-tight bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent" to="/services">Services</Link>
                                <svg xmlns="http://www.w3.org/2000/svg" width="4" height="8" viewbox="0 0 4 8" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.50225 0.5L0 0.98755L2.95244 4L0.0073503 7.0051L0.50225 7.5L4.00225 4L0.50225 0.5Z" fill="#19191B"></path>
                                </svg>
                                <Link class="inline-block text-sm font-medium tracking-tight bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent" href="/services/Support">Support</Link>
                            </div>
                            {/* Title */}
                            <div className="mt-5 max-w-2xl">
                                <h1 className="block font-light logo text-gray-800 text-4xl md:text-5xl lg:text-6xl">
                                    Comprehensive Support
                                </h1>
                            </div>
                            {/* End Title */}
                            <div className="mt-5 max-w-3xl">
                                <p className="text-lg text-gray-600">
                                    At BZ Softech, we understand that success is built on great support. Our support services cover everything from resolving issues to ensuring your customers, products, and services operate seamlessly. Whether you need real-time assistance or ongoing maintenance, we’re here to empower your business and deliver unmatched customer satisfaction.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="max-w-7xl mx-auto px-3 md:px-8 my-8">
                <p className="text-gray-700 text-justify">
                    Our support services are crafted to provide comprehensive assistance tailored to your needs. With BZ Softech, you gain access to:
                    <ul className="list-disc ml-6 mt-4">
                        <li><strong>Customer Support:</strong> We ensure your customers are never left in the dark. From troubleshooting to product inquiries, we’re always on hand to provide fast and efficient solutions, elevating the customer experience and reinforcing your brand loyalty.</li>
                        <li><strong>Product Support:</strong> Keep your products running at peak performance. Our product support services ensure your digital and hardware solutions remain efficient, updated, and ready to deliver the best results, every time.</li>
                        <li><strong>Service Support:</strong> We go beyond traditional support. With our service support, we integrate new services, optimize existing ones, and ensure everything runs smoothly, helping you stay ahead in a competitive landscape.</li>
                    </ul>
                </p>
            </div>

            {/* Image placeholder for support */}
            <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-48">
                <img src="https://img.freepik.com/premium-photo/customer-support-illustration-call-center-vector_906385-42438.jpg" alt="Support Services" className="w-92 mx-auto max-w-92 h-auto" />
            </div>

            <div className="max-w-7xl mx-auto px-4 md:px-32 my-24 space-y-10">
                <h1 className="font-bold logo text-gray-800 md:text-4xl text-2xl">
                    Why Choose BZ Softech for Support?
                </h1>

                <div className="space-y-4">
                    <h1 className="font-light logo text-gray-800 text-4xl">
                        1. Proactive Customer Support
                    </h1>
                    <p className="text-gray-600 text-justify">
                        Don’t wait for issues to arise. With our proactive customer support, we identify and address potential problems before they affect your business. Our team is dedicated to providing top-tier service, keeping your customers satisfied and confident in your brand.
                    </p>
                </div>

                <div className="space-y-4">
                    <h1 className="font-light logo text-gray-800 text-4xl">
                        2. Always-On Product Assistance
                    </h1>
                    <p className="text-gray-600 text-justify">
                        Our product support is available whenever you need it. From resolving technical issues to providing updates and patches, we ensure your products are always functioning at their best, delivering continuous value to your users.
                    </p>
                </div>

                <div className="space-y-4">
                    <h1 className="font-light logo text-gray-800 text-4xl">
                        3. Holistic Service Support
                    </h1>
                    <p className="text-gray-600 text-justify">
                        With our holistic service support, we focus on optimizing your business services. Whether you’re introducing new services or need to fine-tune existing ones, our team ensures that your operations remain uninterrupted and streamlined for success.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Support
