import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { gsap } from "gsap";
import whatsapp from "../assets/whatsapp.svg";

const WhatsAppButton = () => {
  const location = useLocation();
  const buttonRef = useRef(null);

  const showButtonRoutes = ["/", "/about", "/services", "/blog", "/contact"];
  const showButton = showButtonRoutes.includes(location.pathname);

  useEffect(() => {
    let animation;

    if (showButton && buttonRef.current) {
      animation = gsap.fromTo(
        buttonRef.current,
        { opacity: 1, scale: 1 },
        {
          opacity: 0.7,
          scale: 1.1,
          repeat: -1,
          yoyo: true,
          duration: 1.5,
          ease: "power1.inOut",
          onUpdate: () => {
            if (buttonRef.current) {
              buttonRef.current.style.boxShadow = `0 0 ${
                Math.abs(
                  Math.sin(gsap.getProperty(buttonRef.current, "scale") * 10)
                ) * 15
              }px #25D366`;
            }
          },
        }
      );
    }

    // Clean up animation if the button is unmounted or removed
    return () => {
      if (animation) {
        animation.kill();
      }
    };
  }, [showButton]);

  if (!showButton) return null;

  return (
    <a
      href="https://wa.me/+923247730410"
      className="fixed bottom-8 right-8 rounded-full shadow-2xl z-50 flex items-center justify-center"
      target="_blank"
      rel="noopener noreferrer"
      ref={buttonRef}
    >
      <img src={whatsapp} alt="WhatsApp" className="w-10 h-10" />
    </a>
  );
};

export default WhatsAppButton;
