import React from "react";
import blue from "../../assets/blue-left.png";
import orange from "../../assets/orange-bottom.png";
import logo from "../../assets/full1.png";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <div>
      <section className="relative py-20 overflow-hidden">
        <img className="absolute top-0 left-0" src={blue} alt="ee" />
        <img
          className="absolute bottom-0 left-1/2 transform -translate-x-1/2"
          src={orange}
          alt="ee"
        />
        <div className="relative container px-4 mx-auto">
          <div className="max-w-7xl mx-auto">
            <div className="pb-10 mb-10 border-b border-gray-100 items-center">
              <div className="flex flex-wrap -mx-4 items-center">
                <div className="w-full lg:w-1/2 px-4 mb-4 lg:mb-0">
                  <a className="inline-block" href="#">
                    <img className="h-8" src={logo} alt="err" />
                  </a>
                </div>
                <div className="w-full lg:w-1/2 px-4">
                  <div className="sm:flex -mb-4 items-center lg:justify-end">
                    <span className="inline-block text-gray-900 mb-4 mr-8">
                      Ready to get collaborate?
                    </span>
                    <Link
                      className="relative group inline-block w-full sm:w-auto py-3 px-5 mb-4 text-center text-sm font-semibold text-blue-50 bg-blue-800 rounded-md overflow-hidden"
                      to="/contact"
                    >
                      <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-105 transition duration-500"></div>
                      <span className="relative">Contact Us</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap -mx-4 mb-18">
              <div className="w-full lg:w-2/5 px-4 mb-12 lg:mb-0">
                <div className="xs:max-w-xs">
                  <h4 className="font-heading text-2xl font-semibold text-gray-900 mb-4">
                    Pioneer In Excellence
                  </h4>
                  <p className="text-justify">
                    At BZSOFTECH, we are pioneers in excellence, specializing in
                    innovative web and ERP solutions. Our dedicated team
                    empowers businesses across various technologies to enhance
                    efficiency and drive growth. Partner with us to transform
                    your digital landscape and achieve success in today’s
                    competitive market.
                  </p>
                </div>
              </div>
              <div className="w-full sm:w-1/3 lg:w-1/5 px-4 mb-8 sm:mb-0">
                <h5 className="text-lg font-semibold text-gray-900 mb-4">
                  Services
                </h5>
                <ul>
                  <li className="mb-4">
                    <p className="inline-block text-gray-500 hover:text-gray-600">
                      ERP Solutions
                    </p>
                  </li>
                  <li className="mb-4">
                    <p className="inline-block text-gray-500 hover:text-gray-600">
                      WEB APPS
                    </p>
                  </li>
                  <li className="mb-4">
                    <p className="inline-block text-gray-500 hover:text-gray-600">
                      ORACLE APEX
                    </p>
                  </li>
                  <li>
                    <p className="inline-block text-gray-500 hover:text-gray-600">
                      API's Solutions
                    </p>
                  </li>
                </ul>
              </div>
              <div className="w-full sm:w-1/3 lg:w-1/5 px-4 mb-8 sm:mb-0">
                <h5 className="text-lg font-semibold text-gray-900 mb-3">
                  About
                </h5>
                <ul>
                  <li className="mb-4">
                    <Link
                      className="inline-block text-gray-500 hover:text-gray-600"
                      to="/about"
                    >
                      Our Story
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link
                      className="inline-block text-gray-500 hover:text-gray-600"
                      to="/about"
                    >
                      Benefits
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link
                      className="inline-block text-gray-500 hover:text-gray-600"
                      to="/about"
                    >
                      Team
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="inline-block text-gray-500 hover:text-gray-600"
                      to="/career"
                    >
                      Careers
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="w-full sm:w-1/3 lg:w-1/5 px-4">
                <h5 className="text-lg font-semibold text-gray-900 mb-4">
                  Help
                </h5>
                <ul>
                  <li className="mb-4">
                    <Link
                      className="inline-block text-gray-500 hover:text-gray-600"
                      href="/"
                    >
                      FAQs
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="inline-block text-gray-500 hover:text-gray-600"
                      to="/contact"
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="sm:flex items-center justify-between">
              <div className="sm:flex mb-6 sm:mb-0 items-center">
                <p className="inline-block mr-6 sm:mr-12 text-gray-900 hover:text-gray-600">
                  Terms & Conditions
                </p>
                <p className="inline-block text-gray-900 hover:text-gray-600">
                  Privacy Policy
                </p>
              </div>
              <div className="flex items-center">
                <a
                  className="inline-block mr-5 hover:bg-blue-50 rounded-md p-1"
                  href="#"
                >
                  <img
                    src="saturn-assets/images/footers/icon-facebook.svg"
                    alt=""
                  />
                </a>
                <a
                  className="inline-block mr-5 hover:bg-blue-50 rounded-md p-1"
                  href="#"
                >
                  <img
                    src="saturn-assets/images/footers/icon-instagram.svg"
                    alt=""
                  />
                </a>
                <a
                  className="inline-block mr-5 hover:bg-blue-50 rounded-md p-1"
                  href="#"
                >
                  <img
                    src="saturn-assets/images/footers/icon-youtube.svg"
                    alt=""
                  />
                </a>
                <a
                  className="inline-block hover:bg-blue-50 rounded-md p-1"
                  href="#"
                >
                  <img
                    src="saturn-assets/images/footers/icon-linkedin.svg"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
