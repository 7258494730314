import React from "react";
import Wall from "../../assets/avatar.png";
import { Link } from "react-router-dom";

const teamMembers = [
  {
    name: "Owais Majeed",
    title: "Founder, CEO",
    image: Wall,
    linkedin: "#",
    email: "#",
    whatsapp: "#",
  },
  {
    name: "Muhammad Ramish",
    title: "Senior APEX Oracle Developer & Manager",
    image: Wall,
    linkedin: "https://www.linkedin.com/in/muhammad-ramish-75890326a/",
    email: "muhammadramish88@gmail.com",
    whatsapp: "03187020336",
  },
  {
    name: "Muhammad Athar Raza",
    title: "Full Stack Web Engineer",
    image: Wall,
    linkedin: "https://www.linkedin.com/in/atharaza-dev/",
    email: "atharaza.dev@gmail.com",
    whatsapp: "03217678097",
  },
  {
    name: "Aftab Ahmad Khan",
    title: "MERN Stack Developer",
    image: Wall,
    linkedin: "https://www.linkedin.com/in/aftab-ahmad-b381a1293/",
    email: "aftabahmadkhan.dev@gmail.com",
    whatsapp: "03042626916",
  },
  {
    name: "Muhammad Awias Khalid",
    title: "MERN Stack Developer",
    image: Wall,
    linkedin: "https://www.linkedin.com/in/muhammad-awais-1236ab28b/",
    email: "awais09813@gmail.com",
    whatsapp: "03056871204",
  },
  {
    name: "Sajawal Hussain",
    title: "Apex Developer",
    image: Wall,
    linkedin: "#",
    email: "#",
    whatsapp: "#",
  },
  {
    name: "Muhammad Ahsan",
    title: "Apex Developer",
    image: Wall,
    linkedin: "#",
    email: "#",
    whatsapp: "#",
  },
  {
    name: "Muhammad Zeeshan",
    title: "Apex Developer",
    image: Wall,
    linkedin: "#",
    email: "#",
    whatsapp: "#",
  },
  {
    name: "Muhammad Awais",
    title: "Apex Developer",
    image: Wall,
    linkedin: "#",
    email: "#",
    whatsapp: "#",
  },
  {
    name: "Talha Tahir",
    title: "Senior Oracle Apex Developer",
    image: Wall,
    linkedin: "#",
    email: "talhatahirpml123@gmail.com",
    whatsapp: "03068385091",
  },
  {
    name: "Muhammad Amir",
    title: "JR Apex Developer",
    image: Wall,
    linkedin: "#",
    email: "#",
    whatsapp: "#",
  },
  {
    name: "Talha Rehman",
    title: "JR Apex Developer",
    image: Wall,
    linkedin: "#",
    email: "#",
    whatsapp: "#",
  },
];

function Team() {
  const handleSocialClick = (type, value) => {
    if (value === "#") return; // Do nothing for placeholder links
    
    switch (type) {
      case 'linkedin':
        window.open(value, '_blank');
        break;
      case 'email':
        window.location.href = `mailto:${value}`;
        break;
      case 'whatsapp':
        window.open(`https://wa.me/${value}`, '_blank');
        break;
      default:
        break;
    }
  };

  return (
    <>
      <section className="relative py-20 md:py-2 overflow-hidden">
        <img
          className="absolute top-0 left-0"
          src="saturn-assets/images/team/light-blue.png"
          alt=""
        />
        <img
          className="absolute bottom-0 right-0"
          src="saturn-assets/images/team/light-orange.png"
          alt=""
        />
        <div className="relative container px-4 mx-auto">
          <div className="max-w-md md:max-w-2xl mx-auto xl:max-w-7xl">
            <div className="flex flex-wrap -mx-4 mb-18 items-center mb-12">
              <div className="w-full px-8 lg:w-2/3 xl:w-1/2 mb-12 lg:mb-0">
                <div>
                  <h1 className="font-heading mb-4 logo text-5xl xs:text-5xl md:text-6xl font-bold text-gray-900">
                    <span>Team of Experts</span>
                  </h1>
                </div>
              </div>
              <div className="w-full lg:w-1/3 xl:w-1/2 px-4">
                <div className="max-w-sm lg:ml-auto">
                  <p className="text-lg text-gray-500">
                    We are a team of 80+ who are passionate about making your
                    business efficient.
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap -mx-12 -mb-12">
              {teamMembers.map((member, index) => (
                <div
                  key={index}
                  className="w-full md:w-1/2 xl:w-1/4 px-4 mb-12"
                >
                  <div className="max-w-x md:max-w-none mx-auto">
                    <div className="flex flex-col items-center">
                      <img
                        className="block h-48 w-48 rounded-full"
                        src={member.image}
                        alt={member.name}
                      />
                      <div className="inline-flex -mt-6 mb-5 items-center justify-center py-1 px-5 bg-white border border-slate-200 rounded-full">
                        <button
                          className="inline-block mr-3 p-1 hover:bg-orange-100 rounded-md"
                          onClick={() => handleSocialClick('linkedin', member.linkedin)}
                        >
                          <i className="fa-brands fa-linkedin"></i>
                        </button>
                        <button
                          className="inline-block mr-3 p-1 hover:bg-orange-100 rounded-md"
                          onClick={() => handleSocialClick('email', member.email)}
                        >
                          <i className="fa-duotone fa-solid fa-envelope"></i>
                        </button>
                        <button
                          className="inline-block p-1 hover:bg-orange-100 rounded-md"
                          onClick={() => handleSocialClick('whatsapp', member.whatsapp)}
                        >
                          <i className="fa-brands fa-whatsapp"></i>
                        </button>
                      </div>
                      <h5 className="text-2xl font-bold">{member.name}</h5>
                      <span className="text-sm text-gray-500">
                        {member.title}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-20 text-center">
              <Link
                to="/career"
                className="relative group inline-block py-4 px-6 text-blue-50 font-semibold bg-blue-800 rounded-full overflow-hidden"
              >
                <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                <div className="relative flex items-center justify-center">
                  <span className="mr-4">See Open Positions</span>
                  <span>
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.83 5.28999L2.59 1.04999C2.49704 0.956266 2.38644 0.881872 2.26458 0.831103C2.14272 0.780334 2.01202 0.754196 1.88 0.754196C1.74799 0.754196 1.61729 0.780334 1.49543 0.831103C1.37357 0.881872 1.26297 0.956266 1.17 1.04999C0.983753 1.23736 0.879211 1.49081 0.879211 1.75499C0.879211 2.01918 0.983753 2.27263 1.17 2.45999L4.71 5.99999L1.17 9.53999C0.983753 9.72736 0.879211 9.98081 0.879211 10.245C0.879211 10.5092 0.983753 10.7626 1.17 10.95C1.26344 11.0427 1.37426 11.116 1.4961 11.1658C1.61794 11.2155 1.7484 11.2408 1.88 11.24C2.01161 11.2408 2.14207 11.2155 2.26391 11.1658C2.38575 11.116 2.49656 11.0427 2.59 10.95L6.83 6.70999C6.92373 6.61703 6.99813 6.50643 7.04889 6.38457C7.09966 6.26271 7.1258 6.13201 7.1258 5.99999C7.1258 5.86798 7.09966 5.73728 7.04889 5.61542C6.99813 5.49356 6.92373 5.38296 6.83 5.28999Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Team;