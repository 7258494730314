import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function APIs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Array containing card details
  const cardData = [
    {
      id: 1,
      title: "Zatca",
      imageUrl:
        "https://tyk.io/wp-content/uploads/2024/02/Learning-centre_API-Governance.svg",
      link: "/services/consultation",
    },
    {
      id: 2,
      title: "Canonicalization",
      imageUrl:
        "https://tyk.io/wp-content/uploads/2024/02/Learning-centre_API-Governance.svg",
      link: "/services/consultation",
    },
    {
      id: 3,
      title: "Invoice Siging & Hashing",
      imageUrl:
        "https://tyk.io/wp-content/uploads/2024/02/Learning-centre_API-Governance.svg",
      link: "/services/consultation",
    },
    {
      id: 4,
      title: "PDF Tools",
      imageUrl:
        "https://tyk.io/wp-content/uploads/2024/02/Learning-centre_API-Governance.svg",
      link: "/services/consultation",
    },
  ];

  return (
    <div>
      <div className="relative overflow-hidden">
        {/* Gradients */}
        <div
          aria-hidden="true"
          className="flex absolute -top-96 start-1/2 transform -translate-x-1/2"
        >
          <div className="bg-gradient-to-r from-violet-300/50 to-purple-100 blur-3xl w-[25rem] h-[44rem] rotate-[-60deg] transform -translate-x-[10rem]" />
          <div className="bg-gradient-to-tl from-blue-50 via-blue-100 to-blue-50 blur-3xl w-[90rem] h-[50rem] rounded-fulls origin-top-left -rotate-12 -translate-x-[15rem]" />
        </div>
        {/* End Gradients */}
        <div className="relative z-10">
          <div className="max-w-[80rem] mx-auto mt-32 px-4 sm:px-6 lg:px-8 py-10 lg:py-16">
            <div className="max-w-[80rem] text-start mx-auto">
              <div className="flex items-center flex-wrap gap-4">
                <Link
                  className="inline-block text-sm font-medium tracking-tight bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent"
                  to="/"
                >
                  Home
                </Link>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="4"
                  height="8"
                  viewBox="0 0 4 8"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.50225 0.5L0 0.98755L2.95244 4L0.0073503 7.0051L0.50225 7.5L4.00225 4L0.50225 0.5Z"
                    fill="#19191B"
                  ></path>
                </svg>
                <Link
                  className="inline-block text-sm font-medium tracking-tight bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent"
                  to="/products"
                >
                  Products
                </Link>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="4"
                  height="8"
                  viewBox="0 0 4 8"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.50225 0.5L0 0.98755L2.95244 4L0.0073503 7.0051L0.50225 7.5L4.00225 4L0.50225 0.5Z"
                    fill="#19191B"
                  ></path>
                </svg>
                <Link
                  className="inline-block text-sm font-medium tracking-tight bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent"
                  href="/products/webApps"
                >
                  APIs
                </Link>
              </div>
              {/* Title */}
              <div className="mt-5 max-w-3xl">
                <h1 className="block font-light logo text-gray-800 text-4xl md:text-5xl lg:text-6xl">
                  APIs
                </h1>
              </div>
              {/* End Title */}
              <div className="mt-5 max-w-3xl">
                <p className="text-lg text-gray-600">
                  A Document Management System (DMS) is a software solution
                  designed to store, manage, and track electronic documents and
                  images of paper-based information. It facilitates the
                  efficient organization, retrieval, and sharing of documents
                  across an organization, streamlining workflows and ensuring
                  secure access to important data.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="relative pb-20 logo lg:pb-32 overflow-hidden">
        <img
          className="absolute top-0 right-0"
          src="saturn-assets/images/features/star-element-right.png"
          alt=""
        />
        <div className="relative container px-4 mx-auto">
          <div className="max-w-md lg:max-w-7xl mx-auto">
            <div className="flex flex-wrap -mx-4">
              {cardData.map((card) => (
                <div className="w-full lg:w-1/3 px-4 py-3 mb-12 lg:mb-0" key={card.id}>
                  <div>
                    <div className="bg-gradient-to-r from-violet-300/50 h-auto to-purple-100  rounded-xl flex items-center justify-center">
                      <img
                        className="block w-full h-full rounded-xl border shadow-sm"
                        src={card.imageUrl}
                        alt={card.title}
                      />
                    </div>
                    <div className="mt-8">
                      <h3 className="text-3xl font-semibold mb-5">
                        {card.title}
                      </h3>
                      <Link
                        to={card.link}
                        className="inline-flex items-center justify-center w-full lg:w-auto px-5 py-3 text-sm font-semibold text-blue-900 rounded-full border border-gray-100 hover:border-blue-900 transition duration-200"
                      >
                        <span className="mr-3 ">Integrate Now</span>
                        <svg
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.9199 0.62C12.8185 0.375651 12.6243 0.181475 12.3799 0.0799999C12.2597 0.028759 12.1306 0.00157999 11.9999 0H1.99994C1.73472 0 1.48037 0.105357 1.29283 0.292893C1.1053 0.48043 0.999939 0.734784 0.999939 1C0.999939 1.26522 1.1053 1.51957 1.29283 1.70711C1.48037 1.89464 1.73472 2 1.99994 2H9.58994L1.28994 10.29C1.19621 10.383 1.12182 10.4936 1.07105 10.6154C1.02028 10.7373 0.994141 10.868 0.994141 11C0.994141 11.132 1.02028 11.2627 1.07105 11.3846C1.12182 11.5064 1.19621 11.617 1.28994 11.71C1.3829 11.8037 1.4935 11.8781 1.61536 11.9289C1.73722 11.9797 1.86793 12.0058 1.99994 12.0058C2.13195 12.0058 2.26266 11.9797 2.38452 11.9289C2.50638 11.8781 2.61698 11.8037 2.70994 11.71L10.9999 3.41V11C10.9999 11.2652 11.1053 11.5196 11.2928 11.7071C11.4804 11.8946 11.7347 12 11.9999 12C12.2652 12 12.5195 11.8946 12.707 11.7071C12.8946 11.5196 12.9999 11.2652 12.9999 11V1C12.9984 0.869323 12.9712 0.740222 12.9199 0.62Z"
                            fill="blue"
                          ></path>
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default APIs;
